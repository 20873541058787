import React, { memo } from 'react'
import ContactItem from '../../components/simpleComponents/ContactItem'
import Avatar from '../../components/simpleComponents/Avatar'
import { Box } from '@material-ui/core'
import { ContactsResponseType } from '../../providers/APIClient/types'

interface IListItem {
  index: number
  style: {}
  data: {
    current: string,
    contacts: ContactsResponseType,
    onClickFn: (id: string) => void
  }
}

const ContactListItem = memo(
  ({
     data,
     index,
     style
   }: IListItem) => {
    const { current, contacts } = data

    if (!contacts.length) return null
    return (
      <div style={style}>
        <ContactItem
          selected={current === contacts[index].contact_id}
          onClick={() => data.onClickFn(contacts[index].contact_id)}
        >
          <ContactItem.InfoContainer>
            <Avatar
              small
              initials={`${contacts[index].first_name[0]}${contacts[index].last_name[0]}`}
              avatar={contacts[index].profile_photo_url || ''}
            />
            <Box>
              <ContactItem.Title>{`${contacts[index].first_name} ${contacts[index].last_name}`}</ContactItem.Title>
            </Box>
          </ContactItem.InfoContainer>
        </ContactItem>
        <ContactItem.Divider/>
      </div>
    )
  })

export default ContactListItem
