import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const JoinCallButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 12,
      textTransform: 'none'
    }
  })
)(Button)

export default JoinCallButton
