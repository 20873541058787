import { createStyles, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const Header = withStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px'
    }
  })
)(Box)

export default Header
