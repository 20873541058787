import React from 'react'
import { Box } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

interface IContactItem {
  children: React.ReactNode
  onClick? (props: any): void
  selected?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      background: theme.palette.background.default,
      height: 80,
      width: 356,
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.palette.background.paper}`,
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette.background.paper
      }
    },
    selected: {
      background: theme.palette.background.paper,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      paddingLeft: 16
    }
  })
)

const Channel = (props: IContactItem) => {
  const classes = useStyles()
  const boxClass = props.selected ? `${classes.box} ${classes.selected}` : classes.box
  return (
    <Box className={boxClass} onClick={props.onClick}>
      {props.children}
    </Box>
  )
}

export default Channel
