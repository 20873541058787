import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import React from 'react'

const StyledAddButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 30,
      width: 30,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0
    }
  })
)(IconButton)

const AddButton = ({ ...props }) => (
  <StyledAddButton color='primary' aria-label='add-phone' {...props}>
    <AddRoundedIcon/>
  </StyledAddButton>
)

export default AddButton
