import React, { useState } from 'react'
import { Box, IconButton } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import CancelIcon from '@material-ui/icons/Cancel'

interface ISearch {
  onChange (props: any): void
  onClear (): void
  onFocus? (): void
  value: string | undefined
  autofocus?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      height: 40,
      width: 270,
      maxWidth: '100%',
      padding: 10,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 30,
      display: 'flex',
      alignItems: 'center'
    },
    input: {
      border: 0,
      background: 'transparent',
      width: '100%',
      outline: 0
    },
    searchIcon: {
      color: theme.palette.text.disabled,
      marginRight: 10
    },
    focus: {
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.primary.main}`,
      boxSizing: 'border-box'
    },
    clearButton: {
      maxWidth: 30,
      maxHeight: 30,
      padding: 0,
      zIndex: 1
    },
    clearIcon: {
      cursor: 'pointer'
    }
  })
)

const SearchInput = (props: ISearch) => {
  const classes = useStyles()
  const [focused, setFocused] = useState(false)

  return (
    <Box className={focused ? `${classes.search} ${classes.focus}` : classes.search}>
      <SearchIcon className={classes.searchIcon} fontSize='small'/>
      <input
        className={classes.input}
        placeholder='Search channels and contacts'
        onChange={props.onChange}
        onFocus={() => {
          props.onFocus && props.onFocus()
          setFocused(true)
        }}
        onBlur={() => setFocused(false)}
        value={props.value}
        autoFocus={props.autofocus}
      />
      {(focused || props.value) &&
      <IconButton onClick={props.onClear} className={classes.clearButton}>
        <CancelIcon color='secondary' className={classes.clearIcon}/>
      </IconButton>
      }
    </Box>
  )
}

export default SearchInput
