import React from 'react'
import StartCallView from '../Chat/StartCallView'
import { CircularProgress } from '@material-ui/core'

interface IProps {
  history: {[key: string]: any}
}

const LoadingView = (props: IProps) => {
  if (props.history.location.hash === '#contacts') {
    return (
      <StartCallView onClick={() => props.history.push('/video')} />
    )
  }
  return (
    <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <CircularProgress />
    </div>
  )
}

export default LoadingView
