import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const InfoContainer = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: 300
    }
  })
)(Box)

export default InfoContainer
