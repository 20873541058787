import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'

const ClearButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer'
    }
  })
)(CancelIcon)

export default ClearButton
