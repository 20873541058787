import React from 'react'
import { styled } from '@material-ui/core/styles'
import ShareScreenIcon from '../simpleComponents/Icon/ShareScreenIcon'
import { Button, Typography } from '@material-ui/core'

const Container = styled('div')(({ theme }) => ({
  position: 'absolute',
  background: theme.palette.secondary.dark,
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1
}))

const TextContainer = styled('div')(({ theme }) => ({
  padding: '1rem 0'
}))

type ScreenSharingType = {
  onStopSharingClick: () => void
}

export default function ScreenSharingOverlay ({ onStopSharingClick }: ScreenSharingType) {
  return (
        <Container>
            <ShareScreenIcon />
            <TextContainer>
                <Typography variant='h5' style={{ color: '#fff' }}>
                    You’re sharing screen to everyone
                </Typography>
            </TextContainer>
            <Button
                variant='contained'
                color='primary'
                onClick={onStopSharingClick}
            >
                Stop Sharing
            </Button>
        </Container>
  )
}
