import { combineReducers } from 'redux'
import { auth } from './authentication/reducers'
import { loading } from './loading/reducers'
import { user } from '../hooks/useUser/reducer'
import { modal } from '../containers/AddContact/redux/reducers'

const rootReducer = combineReducers({
  auth,
  loading,
  user,
  modal
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
