import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setAuth } from '../../redux/authentication/actions'
import { setLoading } from '../../redux/loading/actions'

export default function useIsLoggedIn () {
  const dispatch = useDispatch()
  const isLoggedIn = useCallback(async () => {
    dispatch(setLoading(true))
    try {
      const kateToken = localStorage.getItem('kateMessangerToken')
      if (!kateToken) return dispatch(setAuth({ loggedIn: false }))
      dispatch(setAuth({ loggedIn: true }))
    } catch (e) {
      console.log('Error on refreshToken: ', e)
      dispatch(setAuth({ loggedIn: false }))
    } finally {
      dispatch(setLoading(false))
    }
  }, [])

  return { isLoggedIn }
}
