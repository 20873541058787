import { createStyles, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const SwitchContainer = withStyles(() =>
  createStyles({
    root: {
      width: '100%',
      minWidth: '250px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  })
)(Box)

export default SwitchContainer
