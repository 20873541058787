import { createStyles, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import React from 'react'
import Header from './Header'
import Container from './Container'
import InfoContainer from './InfoContainer'
import PreviewContainer from './PreviewContainer'
import StyledSwitch from './StyledSwitch'
import Avatar from './Avatar'
import AvatarsContainer from './AvatarsContainer'
import SwitchContainer from './SwitchContainer'
import AudioStrength from './AudioStrength'

interface IProps {
  children: React.ReactNode
}

const RoomContainer = withStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }
  })
)(Box)

const WaitingRoom = (props: IProps) => {
  return (
    <RoomContainer>
      {props.children}
    </RoomContainer>
  )
}

WaitingRoom.Header = Header
WaitingRoom.Container = Container
WaitingRoom.InfoContainer = InfoContainer
WaitingRoom.PreviewContainer = PreviewContainer
WaitingRoom.StyledSwitch = StyledSwitch
WaitingRoom.Avatar = Avatar
WaitingRoom.AvatarsContainer = AvatarsContainer
WaitingRoom.SwitchContainer = SwitchContainer
WaitingRoom.AudioStrength = AudioStrength

export default WaitingRoom
