import React from 'react'
import { IconButton } from '@material-ui/core'

interface IProps {
  fill: string
  style?: any
  onClick? (props: any): void
}

const AddContact = (props: IProps) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' style={props.style}>
      <path
        fill={props.fill}
        d='M10 0.601562C6.60642 0.601562 5.20002 2.77836 5.20002 5.40156C5.20002 6.28476 5.6219 7.17187 5.6219 7.17187C5.4523 7.26947 5.17337 7.57929 5.24377 8.12969C5.37497 9.15609 5.81995 9.41746 6.10315 9.43906C6.21115 10.3967 7.24002 11.6216 7.60002 11.8V13.4C6.80002 15.8 0.400024 14.2 0.400024 19.8H9.61877C9.35237 19.048 9.20002 18.2432 9.20002 17.4C9.20002 14.5688 10.8381 12.1268 13.2141 10.95C13.5413 10.494 13.8409 9.93266 13.8969 9.43906C14.1801 9.41746 14.6251 9.15609 14.7563 8.12969C14.8267 7.57849 14.5477 7.26947 14.3781 7.17187C14.3781 7.17187 14.8 6.37036 14.8 5.40156C14.8 3.45916 14.0376 1.80156 12.4 1.80156C12.4 1.80156 11.8312 0.601562 10 0.601562ZM16.4 11.8C13.3072 11.8 10.8 14.3072 10.8 17.4C10.8 20.4928 13.3072 23 16.4 23C19.4928 23 22 20.4928 22 17.4C22 14.3072 19.4928 11.8 16.4 11.8ZM16.4 14.2C16.8416 14.2 17.2 14.5576 17.2 15V16.6H18.8C19.2416 16.6 19.6 16.9576 19.6 17.4C19.6 17.8424 19.2416 18.2 18.8 18.2H17.2V19.8C17.2 20.2424 16.8416 20.6 16.4 20.6C15.9584 20.6 15.6 20.2424 15.6 19.8V18.2H14C13.5584 18.2 13.2 17.8424 13.2 17.4C13.2 16.9576 13.5584 16.6 14 16.6H15.6V15C15.6 14.5576 15.9584 14.2 16.4 14.2Z'
      />
    </svg>
  )
}

export default AddContact
