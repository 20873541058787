import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const HeaderContainer = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: `1px solid ${theme.palette.background.paper}`,
      padding: 20
    }
  })
)(Box)

export default HeaderContainer
