import { SET_MODAL, SetModalAction } from './types'

const INITIAL_STATE: boolean = false

export const modal = (
  state = INITIAL_STATE,
  action: SetModalAction
): boolean => {
  switch (action.type) {
    case SET_MODAL:
      return action.payload
    default:
      return state
  }
}
