import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const NewMessages = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'white',
      fontSize: 12,
      fontWeight: 'bold',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 20,
      width: 20,
      height: 20,
      padding: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
)(Typography)

export default NewMessages
