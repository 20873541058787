import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import React, { ReactNode } from 'react'
import Avatar from './Avatar'
import Title from './Title'
import Subtitle from './Subtitle'
import CallButton from './CallButton'
import TitleContainer from './TitleContainer'

interface IProps {
  children: ReactNode
}

const Header = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minWidth: '100%',
      height: 70,
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px'
    }
  })
)(Box)

const ChatHeader = (props: IProps) => (
  <Header>
    {props.children}
  </Header>
)

ChatHeader.Avatar = Avatar
ChatHeader.Title = Title
ChatHeader.TitleContainer = TitleContainer
ChatHeader.Subtitle = Subtitle
ChatHeader.CallButton = CallButton

export default ChatHeader
