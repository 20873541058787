import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import MuiAvatar from '@material-ui/core/Avatar'
import React from 'react'
import { cyan } from '@material-ui/core/colors'

interface IChannelAvatar {
  initials: string
  avatar: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    defaultAvatar: {
      width: 40,
      height: 40,
      backgroundColor: cyan[500],
      fontSize: 18,
      fontWeight: 'bold',
      marginRight: 15
    }
  })
)

const Avatar = (props: IChannelAvatar) => {
  const classes = useStyles()

  return (
    <MuiAvatar className={classes.defaultAvatar} alt={props.initials} src={props.avatar}>
        {props.initials}
    </MuiAvatar>
  )
}

export default Avatar
