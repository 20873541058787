import React, { FC } from 'react'
import { Message as TwilioMessage } from 'twilio-chat/lib/message'
import { Box, Button, Typography } from '@material-ui/core'
import Message from '../../components/simpleComponents/Message'
import { User } from 'twilio-chat'
import { ContactsResponseType } from '../../providers/APIClient/types'
import { format } from 'date-fns'

interface ITwilioMessage extends TwilioMessage {
  attributes: {
    [key: string]: any;
  }
}

interface IChatMessagesView {
  messages: (ITwilioMessage | {[key: string]: any})[] | undefined
  user: User | undefined
  contacts: ContactsResponseType | undefined
  profilePhoto: { profile_photo_url: string, profile_photo_object_id: string } | undefined
  onResend: (id: string) => void
  onJoinCall: () => void
}

const ChatMessagesView: FC<IChatMessagesView> = ({
 messages,
 user, contacts,
 profilePhoto,
 onResend,
 onJoinCall
}) => {
  return (
    <>
      {messages?.map(message => {
        const sentMessage = message.author === user?.identity
        const contact = contacts?.find(contactItem => contactItem.contact_account_id === message.author)
        const status = message.attributes.status
        return (
          <Message key={message.attributes.post_id} status={status}>
            <Message.Avatar
              initials={sentMessage ? 'ME' : message.attributes?.initials}
              avatar={sentMessage ? (profilePhoto?.profile_photo_url || '') : (contact?.profile_photo_url || '')}
            />
            <Box>
              <Message.InfoContainer>
                <Message.Sender>
                  {sentMessage
                    ? 'You'
                    : `${message.attributes['first name']} ${message.attributes['last name']}`}
                </Message.Sender>
                <Message.Time>{format(message.timestamp, 'do MMM RRRR H:mm aaa')}</Message.Time>
              </Message.InfoContainer>
                <Message.Content isSend={sentMessage}>
                  {message.body || <strong>{console.log(message)}[Audio or Photo message]</strong>}
                  {message.attributes.video_invite
                  && <Message.Invite>
                    <Button
                      color='primary'
                      variant='contained'
                      size='small'
                      onClick={onJoinCall}
                    >
                      Join Call
                    </Button>
                    <Button
                      color='primary'
                      size='small'
                      disabled={true}
                    >
                      Decline
                    </Button>
                  </Message.Invite>}
                </Message.Content>
              <Message.Error>
                {status === 'error'
                    ? <Typography variant='caption' color='error'>
                      Error on sending <Button size='small' onClick={() => onResend(message.attributes.post_id)}>Resend</Button>
                    </Typography>
                    : null}
              </Message.Error>
            </Box>
          </Message>
        )
      })}
    </>
  )
}

export default ChatMessagesView
