import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import './types'
import App from './App'
import configureStore from './redux/store'
import { MuiThemeProvider } from '@material-ui/core/styles'
import theme from './theme'
import { CssBaseline } from '@material-ui/core'
import { Provider as ReduxProvider, useSelector } from 'react-redux'

import '@opentok/client'
import './Vonage/index.css'
import './Vonage/polyfills'
import { APIClientProvider } from './providers/APIClient'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import LoginPage from './pages/LoginPage'
import useIsLoggedIn from './hooks/useIsLoggedIn/useIsLoggedIn'
import { RootState } from './redux/reducers'
import CircularProgress from '@material-ui/core/CircularProgress'

const Main = () => {
  const { isLoggedIn } = useIsLoggedIn()
  const loading = useSelector((state: RootState) => state.loading)

  useEffect(() => {
    isLoggedIn()
  }, [])

  if (loading) {
    return (
            <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </div>
    )
  }

  return (
        <Router>
            <Switch>
                <Route path='/login' component={LoginPage} />
                <PrivateRoute path='/'>
                    <App />
                </PrivateRoute>
                <Redirect to='/'/>
            </Switch>
        </Router>
  )
}

ReactDOM.render(
  <ReduxProvider store={configureStore()}>
    <MuiThemeProvider theme={theme}>
        <APIClientProvider>
            <CssBaseline />
            <Main />
        </APIClientProvider>
    </MuiThemeProvider>
  </ReduxProvider>,
  document.getElementById('root')
)
