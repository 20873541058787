import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const Message = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 14
    }
  })
)(Typography)

export default Message
