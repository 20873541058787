import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import VideocamIcon from '@material-ui/icons/Videocam'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import ChatHeader from '../../components/simpleComponents/ChatHeader'
import { Member } from 'twilio-chat/lib/member'
import MessagesList from '../MessagesList'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      background: theme.palette.background.paper,
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    name: {
      fontSize: 56,
      fontWeight: 'bold',
      marginBottom: 10
    },
    subtitle: {
      fontSize: 20,
      marginBottom: 30
    },
    members: {
      display: 'flex',
      marginBottom: 80,
      alignItems: 'flex-end'
    },
    button: {
      width: 260,
      height: 66
    },
    buttonIcon: {
      marginRight: 10
    }
  })
)

interface IChatView {
  handleVideoCall: () => void
  members: Member[] | undefined
  channelInitials: string
  channelName: string
  channelPhoto: string
}

const ChatView: FC<IChatView> = ({
  handleVideoCall,
  channelInitials,
  channelName,
  channelPhoto
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.box}>
      <ChatHeader>
        <ChatHeader.TitleContainer>
          <ChatHeader.Avatar avatar={channelPhoto} initials={channelInitials} />
          <div>
            <ChatHeader.Title>{channelName}</ChatHeader.Title>
          </div>
        </ChatHeader.TitleContainer>
        <ChatHeader.CallButton
          variant='contained'
          color='primary'
          onClick={handleVideoCall}
        >
          <VideocamIcon fontSize='small' />
          Video Call
        </ChatHeader.CallButton>
      </ChatHeader>
      <MessagesList />
    </Box>
  )
}

export default ChatView
