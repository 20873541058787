import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'

const ListDivider = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 291,
      height: 1,
      background: theme.palette.background.paper,
      marginLeft: 65
    }
  })
)(Divider)

export default ListDivider
