import React, {
  FC,
  useCallback,
  useContext,
  useMemo
} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { TwilioChatClientContext } from '../../providers/TwilioChat'
import getChannelName from '../ChannelsList/getChannelName'
import getChannelInitials from '../ChannelsList/getChannelInitials'
import ChatView from './ChatView'
import { useQuery } from 'react-query'
import { APIClientContext } from '../../providers/APIClient'

const Chat: FC = props => {
  const APIClient = useContext(APIClientContext)
  const history = useHistory()
  const { roomId } = useParams()
  const chatClient = useContext(TwilioChatClientContext)

  const getChannel = async (key: string, roomId: string = '') => {
    if (!roomId) return Promise.resolve(null)
    return chatClient?.getChannelBySid(roomId)
  }

  const { data: channel } = useQuery(['channel', roomId], getChannel, { enabled: !!chatClient })

  const { data: contactsRes } = useQuery('contacts', APIClient.getContacts)

  const handleVideoCall = useCallback(() => {
    history.push(`/video/${roomId}`)
  }, [roomId])

  const channelName = useMemo(() => {
    if (channel) return getChannelName(channel, chatClient?.user)
    return ''
  }, [chatClient, channel])

  const channelInitials = useMemo(() => {
    if (channel) return getChannelInitials(getChannelName(channel, chatClient?.user))
    return ''
  }, [chatClient, channel])

  const channelPhoto = useMemo(() => {
    return contactsRes
        ?.data
        .find(contact => contact.contact_account_id === channel?.createdBy)
        ?.profile_photo_url || ''
  }, [chatClient, channel, contactsRes])

  return (
    <ChatView
      channelInitials={channelInitials}
      channelName={channelName}
      channelPhoto={channelPhoto}
      members={undefined}
      handleVideoCall={handleVideoCall}
    />
  )
}

export default Chat
