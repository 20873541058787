import React from 'react'
import { Box } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AddChannelOrContact from './AddChannelOrContact'
import ListContainer from './ListContainer'
import ActionsContainer from './ActionsContainer'
import Title from './Title'
import HeaderContainer from './HeaderContainer'

interface IList {
  children: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: 356,
      minWidth: 356,
      borderLeft: `1px solid ${theme.palette.background.paper}`,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflowX: 'hidden'
    }
  })
)

const ConversationsList = (props: IList) => {
  const classes = useStyles()

  return <Box className={classes.container}>{props.children}</Box>
}

ConversationsList.AddChannelOrContact = AddChannelOrContact
ConversationsList.ListContainer = ListContainer
ConversationsList.ActionsContainer = ActionsContainer
ConversationsList.Title = Title
ConversationsList.HeaderContainer = HeaderContainer

export default ConversationsList
