import React, { FC } from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ChannelAvatar from '../../components/simpleComponents/Avatar'
import VideocamIcon from '@material-ui/icons/Videocam'
import { ContactsResponseType } from '../../providers/APIClient/types'

interface IStartCall {
  members?: ContactsResponseType
  name?: string
  onClick (props: any): void
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      background: theme.palette.background.paper,
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingLeft: 80
    },
    name: {
      fontSize: 56,
      fontWeight: 'bold',
      marginBottom: 10
    },
    subtitle: {
      fontSize: 20,
      marginBottom: 30
    },
    members: {
      display: 'flex',
      marginBottom: 80,
      alignItems: 'flex-end'
    },
    button: {
      width: 260,
      height: 66
    },
    buttonIcon: {
      marginRight: 10
    }
  })
)

const StartCallView: FC<IStartCall> = props => {
  const classes = useStyles()

  return (
    <Box className={classes.box}>
      <Typography className={classes.name} variant='h1'>
        {props.name || 'Kate messenger'}
      </Typography>
      <Typography className={classes.subtitle} variant='h2'>
        Communicate easily
      </Typography>
      <Box className={classes.members}>
        {props.members?.map((member, index) => (
          <ChannelAvatar
            key={member.contact_id}
            members={0}
            avatar={member.profile_photo_url}
            initials={`${member.first_name[0]}${member.last_name[0]}`}
            extraLarge={index === 0}
          />
        ))}
      </Box>
      <Button
        className={classes.button}
        variant='contained'
        color='primary'
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <VideocamIcon className={classes.buttonIcon} fontSize='large' />
        Start video call
      </Button>
    </Box>
  )
}

export default StartCallView
