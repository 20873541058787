import ContactItem from '../../components/simpleComponents/ContactItem'
import { Box, Button } from '@material-ui/core'
import AddContact from '../../components/simpleComponents/Icon/AddContact'
import React, {FC, memo, useContext, useEffect} from 'react'
import Avatar from '../../components/simpleComponents/Avatar'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import Autosizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as ContactItemsList } from 'react-window'
import { ContactsResponseType } from '../../providers/APIClient/types'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useDispatch } from 'react-redux'
import { setModal } from '../AddContact/redux/actions'
import theme from '../../theme'
import {APIClientContext} from '../../providers/APIClient'

interface IListItem {
  index: number
  style: {}
  data: {
    current: string,
    contacts: ContactsResponseType,
    onClickFn: (id: string) => void
  }
}

const ListItem = memo(
({
   data,
   index,
   style
}: IListItem) => {
  const { current, contacts } = data

  if (!contacts.length) return null
  return (
    <div style={style}>
      <ContactItem
        selected={current === contacts[index].contact_id}
        onClick={() => data.onClickFn(contacts[index].contact_id)}
      >
        <ContactItem.InfoContainer>
          <Avatar
            small
            initials={`${contacts[index].first_name[0]}${contacts[index].last_name[0]}`}
            avatar={contacts[index].profile_photo_url || ''}
          />
          <Box>
            <ContactItem.Title>{`${contacts[index].first_name} ${contacts[index].last_name}`}</ContactItem.Title>
            <ContactItem.Caption>{contacts[index].kate_number || contacts[index].email}</ContactItem.Caption>
          </Box>
        </ContactItem.InfoContainer>
      </ContactItem>
      <ContactItem.Divider/>
    </div>
  )
})

const ContactsList: FC = () => {
  const history = useHistory()
  const { roomId } = useParams()
  const dispatch = useDispatch()
  const APIClient = useContext(APIClientContext)

  const { status, data: contactsRes } = useQuery('contacts', APIClient.getContacts)

  useEffect(() => {
    if (contactsRes && contactsRes.data[0] && !roomId) {
      history.replace(`/chat/${contactsRes.data[0].contact_id}#contacts`)
    }
  }, [status, history, roomId])

  if (status === 'success') {

    const contacts = contactsRes?.data

    const itemData = {
      contacts,
      onClickFn: (id: string) => history.push(`/chat/${id}#contacts`),
      current: roomId
    }
    return (
      <>
      <ContactItem>
        <Button color='primary' onClick={() => dispatch(setModal(true))}>
          <AddContact fill={theme.palette.primary.main} style={{ marginRight: 20 }}/>
          Add Contact
        </Button>
      </ContactItem>
      <ContactItem.Divider/>
        <Autosizer>
          {({ height, width }) => (
            <ContactItemsList
              height={height}
              itemCount={contactsRes?.data.length || 0}
              itemSize={60}
              width={width}
              itemData={itemData}
            >
              {ListItem}
            </ContactItemsList>
          )}
        </Autosizer>
    </>
    )
  }

  if (status === 'error') {
    return (
      <ContactItem>
        <ContactItem.InfoContainer>
          <ContactItem.Title>Ups, there's been some error</ContactItem.Title>
        </ContactItem.InfoContainer>
      </ContactItem>
    )
  }

  return (
    <div style={{ width: '100%', padding: '20px', display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  )
}

export default ContactsList
