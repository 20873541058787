import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const Invite = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 10
    }
  })
)(Box)

export default Invite
