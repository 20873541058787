import { createMuiTheme } from '@material-ui/core'

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    sidebarWidth: number
    sidebarMobileHeight: number
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number
    sidebarMobileHeight?: number
  }
}

export default createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#8FC3F1',
      main: '#1D70B6',
      dark: '#1D70B6'
    },
    secondary: {
      light: '#9e9e9e',
      main: '#707070',
      dark: '#222'
    },
    background: {
      default: '#fff',
      paper: '#F8F8FA'
    },
    text: {
      primary: '#171717',
      secondary: '#707070',
      disabled: '#bebebe'
    },
    divider: '#EDEDED'
  },
  sidebarWidth: 260,
  sidebarMobileHeight: 90,
  typography: {
    fontFamily: 'Open Sans, sans-serif'
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 2,
        textTransform: 'none',
        fontSize: 16,
        fontWeight: 600
      }
    }
  }
})
