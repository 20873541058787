import { useCallback, useContext, useEffect, useState } from 'react'
import { TwilioChatClientContext } from '../../providers/TwilioChat'
import { useQuery } from 'react-query'
import { ITwilioMessage } from '../../types'
import { Paginator } from 'twilio-chat/lib/interfaces/paginator'
import { Channel } from 'twilio-chat/lib/channel'
import { Message } from 'twilio-chat/lib/message'

interface IChannel extends Channel {
  attributes: {
    [id: string]: IMessage
  }
}

interface IMessage extends Message {
  attributes: {
    [key: string]: any
    ['first name']?: string
  }
}

interface ILastMessageForChannels {
  [id: string]: IMessage
}

function useChannelsLastMessage (channels: Paginator<Channel> | undefined) {
  const [channelsLastMessage, setChannelsLastMessage] = useState<ILastMessageForChannels | null | undefined>({})
  const twilioClient = useContext(TwilioChatClientContext)

  // Get Last Messages for each channel
  const getLastMessage = useCallback(() => {
    if (channels?.items) {
      return Promise.all(channels.items.map((channel) => channel.getMessages(1)))
    }

    return Promise.resolve(null)
  }, [channels])

  const { data, status } = useQuery('messages', getLastMessage,  { enabled: !!channels })

  useEffect(() => {
    if (!data || !data.length || status !== 'success') return

    const arrayMap = data.map(paginator => {
      if (!paginator.items.length) return []

      return [paginator.items[0].channel.sid, paginator.items[0]]
    })

    setChannelsLastMessage(Object.fromEntries(arrayMap))
  }, [data, status])

  useEffect(() => {
    if (!twilioClient) return

    async function messageAddedToChannel (message: ITwilioMessage) {
      setChannelsLastMessage(prevState => ({
        ...prevState,
        [message.channel.sid]: message
      }))
    }

    twilioClient.on('messageAdded', messageAddedToChannel)
    return () => {
      twilioClient.off('messageAdded', messageAddedToChannel)
    }
  }, [twilioClient])

  return {
    channelsLastMessage,
    channelsLastMessageStatus: status
  }
}

export default useChannelsLastMessage
