import React, { FC } from 'react'
import ConversationsList from '../../components/simpleComponents/ConversationsList'
import AppView from '../../containers/AppView/AppView'
import ChannelsList from '../../containers/ChannelsList'
import Chat from '../../containers/Chat'
import ContactsList from '../../containers/ContactsList'
import SearchList from '../../containers/SearchList'

interface IProps {
  listType: string
}

const renderList = (props: IProps) => {
  switch (props.listType) {
    case 'channels':
    case '':
      return <ChannelsList/>
    case 'contacts':
      return <ContactsList/>
    default:
      return <SearchList/>
  }
}

const ChatChannelPageView: FC<IProps> = (props) => {
  return (
    <AppView
      list={
        <ConversationsList.ListContainer>
          {renderList(props)}
        </ConversationsList.ListContainer>
      }
    >
      <Chat />
    </AppView>
  )
}

export default ChatChannelPageView
