import React, { ReactNode, useCallback } from 'react'
import WaitingRoomView from './WaitingRoomView'
import useAudio from './useAudio'
import useChannel from './useChannel'
import { Stream } from '@opentok/client'
import { useHistory } from 'react-router-dom'

interface IProps {
  onJoin: () => void
  video: boolean
  toggleVideo: () => void
  audio: boolean
  toggleAudio: () => void
  children: ReactNode
  streams: Stream[] | null
}

const WaitingRoom = (props: IProps) => {
  const state = useChannel(props.streams)
  const volume = useAudio(props.audio)
  const history = useHistory()

  const handleGoToMainPage = useCallback(() => {
    history.push(`/`)
  }, [history])

  return (
    <WaitingRoomView
        volume={Math.round(volume / 10)}
        onKateIconClick={handleGoToMainPage}
        {...props}
        {...state}
    >
      {props.children}
    </WaitingRoomView>
  )
}

export default WaitingRoom
