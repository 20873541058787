import { useParams } from 'react-router-dom'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { TwilioChatClientContext } from '../../providers/TwilioChat'
import { useMutation, useQuery } from 'react-query'
import getChannelName from '../ChannelsList/getChannelName'
import { Stream } from '@opentok/client'
import { APIClientContext } from '../../providers/APIClient'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import { Channel } from 'twilio-chat/lib/channel'

interface IChannel extends Channel {
  attributes: {
    [key: string]: any
  }
}

const useChannel = (streams: Stream[] | null) => {
  const APIClient = useContext(APIClientContext)
  const { channelId } = useParams()
  const client = useContext(TwilioChatClientContext)
  const user = useSelector((state: RootState) => state.user)

  const getChannel: () => Promise<IChannel | null> = async () => {
    if (!channelId || !client) return Promise.resolve(null)
    const channelRes = await client.getChannelBySid(channelId)
    return channelRes
  }

  const { data: channel, refetch: refetchChannel } = useQuery(['channel'], getChannel)

  useEffect(() => {
    refetchChannel()
  }, [channelId, client])

  const channelName = useMemo(() => {
    if (channel) return getChannelName(channel, client?.user)
    return ''
  }, [client, channel])

  const streamsNumber = useMemo(() => streams?.length, [streams])

  const guests = useMemo(() => {
    return streams?.map(stream => stream.name)
  }, [streams])

  const { data: contactsRes } = useQuery('contacts', APIClient.getContacts)

  const guestsAvatars = useMemo(() => {
    const avatarsObject: {[key: string]: string} = {}
    contactsRes?.data?.map(contact => {
      const fullName = `${contact.first_name || ''} ${contact.last_name || ''}`
      if (guests?.includes(fullName)) {
        avatarsObject[fullName] = contact.profile_photo_url || ''
      }
    })
    return avatarsObject
  }, [contactsRes, guests])

  const copyText = useMemo(() => {
    return window.location.href
  }, [])

  const [sendMessageMutation] = useMutation(APIClient.sendMessage)

  const sendInviteMessage = useCallback(() => {
    if (streams?.length) return null
    if (channel?.attributes && channel?.attributes?.group_id) {
      return sendMessageMutation({
        group_id: channel.attributes.group_id.toString(),
        text: `${user.first_name} started a video conference`,
        video_invite: true
      })
    }
  }, [channel, sendMessageMutation, streams])

  return {
    channelName,
    streamsNumber,
    copyText,
    guests,
    guestsAvatars,
    sendInviteMessage
  }

}

export default useChannel
