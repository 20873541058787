import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ResultsHeader from '../../components/simpleComponents/Search/ResultsHeader'
import ListsDivider from '../../components/simpleComponents/Search/ListsDivider'
import { TwilioChatClientContext } from '../../providers/TwilioChat'
import { useQuery } from 'react-query'
import { ContactsResponseType } from '../../providers/APIClient/types'
import { Channel } from 'twilio-chat/lib/channel'
import getChannelName from './getChannelName'
import Autosizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as ContactItemsList } from 'react-window'
import ContactListItem from './ContactItem'
import ChannelListItem from './ChannelItem'
import { CircularProgress } from '@material-ui/core'
import { APIClientContext } from '../../providers/APIClient'

interface IChannel extends Channel {
  attributes: {
    [key: string]: any;
  }
}

const SearchList: FC = () => {
  const APIClient = useContext(APIClientContext)
  const history = useHistory()
  const { roomId } = useParams()
  const searchValue = history.location.search.substr(1)
  const searchRegex = useMemo(() => new RegExp(searchValue ? searchValue + '.*' : '', 'i'), [searchValue])
  const twilioClient = useContext(TwilioChatClientContext)

  const [channels, setSearchChannels] = useState<IChannel[]>([])
  const [contacts, setSearchContacts] = useState<ContactsResponseType>([])

  // get contacts
  const { status: contactStatus, data: contactsRes } = useQuery('contacts', APIClient.getContacts)

  // get channels
  const getChatChannels = async () => {
    if (twilioClient) {
      return twilioClient.getSubscribedChannels()
    }
  }

  const { status, data: allChannels } = useQuery(twilioClient ? 'channels' : false, getChatChannels)

  useEffect(() => {
    if (contactsRes) {
      const searchContacts = contactsRes.data.filter(value => value.first_name.match(searchRegex) || value.last_name.match(searchRegex))
      setSearchContacts(searchContacts || [])
    }

    if (allChannels) {
      const searchChannels = allChannels.items?.filter(value => {
        const channelName = getChannelName(value, twilioClient?.user)
        return channelName.match(searchRegex)
      })
      setSearchChannels(searchChannels || [])
    }

  }, [searchRegex, allChannels, contactsRes, twilioClient])

  const contactItemData = {
    contacts,
    onClickFn: (id: string) => history.push(`/chat/${id}?${searchValue}#contacts`),
    current: roomId
  }

  const channelItemData = {
    channels,
    contacts: contactsRes?.data || [],
    onClickFn: (id: string) => history.push(`/chat/${id}?${searchValue}#channels`),
    current: roomId,
    user: twilioClient?.user
  }

  if (contactStatus === 'loading' || status === 'loading') {
    return (
      <>
        <ResultsHeader>Channels</ResultsHeader>
        <div style={{ height: '45%', maxHeight: '45%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
        <ListsDivider />
        <ResultsHeader>Contacts</ResultsHeader>
        <div style={{ height: '45%', maxHeight: '45%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      </>
    )
  }

  return (
    <>
      <ResultsHeader>Channels</ResultsHeader>
      <div style={{ height: '45%', maxHeight: '45%' }}>
        <Autosizer>
          {({ height, width }) => (
            <ContactItemsList
              height={height}
              itemCount={channels.length || 0}
              itemSize={80}
              width={width}
              itemData={channelItemData}
            >
              {ChannelListItem}
            </ContactItemsList>
          )}
        </Autosizer>
      </div>
      <ListsDivider />
      <ResultsHeader>Contacts</ResultsHeader>
      <div style={{ height: '45%', maxHeight: '45%' }}>
        <Autosizer>
          {({ height, width }) => (
            <ContactItemsList
              height={height}
              itemCount={contacts.length || 0}
              itemSize={60}
              width={width}
              itemData={contactItemData}
            >
              {ContactListItem}
            </ContactItemsList>
          )}
        </Autosizer>
      </div>
    </>
  )
}

export default SearchList
