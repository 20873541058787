import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import React, { ReactNode } from 'react'
import RecordButton from './RecordButton'
import TextInput from './TextInput'

interface IProps {
  children: ReactNode
}

const Footer = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minWidth: '100%',
      height: 66,
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px'
    }
  })
)(Box)

const ChatFooter = (props: IProps) => (
  <Footer>
    {props.children}
  </Footer>
)

ChatFooter.Record = RecordButton
ChatFooter.TextInput = TextInput

export default ChatFooter
