import { useCallback, useContext, useEffect, useState } from 'react'
import { ITwilioMessage } from '../../types'
import { TwilioChatClientContext } from '../../providers/TwilioChat'

interface IChannelsUnreadMessagesCount {
  [id: string]: number
}

function useChannelsUnreadMessagesCount (roomId: string | undefined) {
  const [channelsUnreadMessagesCount, setChannelsUnreadMessagesCount] = useState<IChannelsUnreadMessagesCount>()
  const twilioClient = useContext(TwilioChatClientContext)

  useEffect(() => {
    if (!roomId || !channelsUnreadMessagesCount) return

    if (channelsUnreadMessagesCount[roomId] && channelsUnreadMessagesCount[roomId] > 0) {
      setChannelsUnreadMessagesCount(prevState => ({
        ...prevState,
        [roomId]: 0
      }))
    }
  }, [roomId, channelsUnreadMessagesCount])

  useEffect(() => {
    if (!twilioClient) return

    async function messageAddedToChannel (message: ITwilioMessage) {
      if (message.channel.sid === roomId) return

      setChannelsUnreadMessagesCount(prevState => ({
        ...prevState,
        [message.channel.sid]: prevState ? prevState[message.channel.sid] + 1 : 1
      }))
    }

    twilioClient.on('messageAdded', messageAddedToChannel)
    return () => {
      twilioClient.off('messageAdded', messageAddedToChannel)
    }
  }, [twilioClient, roomId])

  return channelsUnreadMessagesCount
}

export default useChannelsUnreadMessagesCount
