import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const RecordButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 20,
      width: 40,
      height: 40,
      minWidth: 40
    }
  })
)(Button)

export default RecordButton
