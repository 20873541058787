// @ts-ignore
import { OTPublisher } from 'opentok-react'
import React from 'react'
import { Typography } from '@material-ui/core'
import PeopleIcon from '@material-ui/icons/People'

interface IProps {
  className?: string
  session?: any
  properties: { [key: string]: any }
  eventHandlers?: { [key: string]: (props: any) => void}
  onPublish: () => void
  onError: (error: Error) => void
  videoSource: string
}

const Publisher = (props: IProps) => {
  return (
    <div style={{
      position: 'absolute',
      right: 0,
      top: 0,
      display: 'flex',
      zIndex: 3,
      flexDirection: 'column',
      boxShadow: 'rgba(0,0,0,0.5) 0px 2px 20px'
    }}>
      {/*<div style={{*/}
      {/*  background: '#fff',*/}
      {/*  display: 'flex',*/}
      {/*  flexDirection: 'row',*/}
      {/*  justifyContent: 'space-between',*/}
      {/*  alignItems: 'center',*/}
      {/*  padding: '0 5px'*/}
      {/*}}>*/}
      {/*  <div>*/}
      {/*    <PeopleIcon />*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    {props.session.streams.length()}*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div style={{
        position: 'relative',
        display: props.videoSource === 'screen' ? 'none' : 'flex',
        width: 120,
        height: 120
      }}>
        <OTPublisher
          session={props.session}
          properties={{
            ...props.properties,
            // insertDefaultUI: 'false',
            // insertMode: 'append',
            width: 120,
            height: 120,
            resolution: '1280x720'
          }}
          eventHandlers={props.eventHandlers}
          onPublish={props.onPublish}
          onError={props.onError}
          // showControls={false}
          style={{
            width: 120,
            height: 120,
            audioLevelDisplayMode: 'on',
            nameDisplayMode: 'off'
          }}
        />
        <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: 30,
              padding: 5,
              /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,000000+100&0+0,1+100 */
              background: 'linear-gradient(to bottom,  rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 100%)' /* FF3.6-15 */
            }}
        >
          <Typography style={{ color: '#fff', fontWeight: 700 }} variant='body2'>
            You
          </Typography>
        </div>
      </div>
      {props.videoSource === 'screen' && (
        <div style={{
          border: 'solid 2px #1fa68b'
          // borderRadius: '6px',
          // marginLeft: '10px'
        }}>
        <OTPublisher
          className='screenSharing'
          session={props.session}
          eventHandlers={props.eventHandlers}
          onPublish={props.onPublish}
          onError={props.onError}
          properties={{
            ...props.properties,
            publishVideo: true,
            publishAudio: false,
            name: `Screen capture by ${props.properties.name}`,
            videoSource: 'screen',
            width: 120,
            height: 120,
            // insertDefaultUI: 'false',
            // insertMode: 'append',
            resolution: '1280x720'
          }}
        />
        </div>
      )}
    </div>
  )
}

export default Publisher
