import React, { ReactNode } from 'react'
import { createStyles, withStyles, Theme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import Container from './Container'
import LargeButton from './LargeButton'
import TextInput from './TextInput'
import AddButton from './AddButton'
import ClearButton from './ClearButton'

interface IProps {
  children: ReactNode
}

const StyledBox = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 350,
      maxWidth: '100%'
    }
  })
)(Box)

const NewContact = (props: IProps) => (
  <StyledBox>
    {props.children}
  </StyledBox>
)

NewContact.Container = Container
NewContact.LargeButton = LargeButton
NewContact.TextInput = TextInput
NewContact.AddButton = AddButton
NewContact.ClearButton = ClearButton

export default NewContact
