import React, {FC, forwardRef, HTMLProps, Ref, RefObject} from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Box, InputBaseProps, InputProps } from '@material-ui/core'
import PhotoIcon from '@material-ui/icons/Photo'

interface IProps extends React.HTMLProps<HTMLInputElement> {
  onIconClick (): void
  ref?: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      borderRadius: 23,
      width: '100%',
      minWidth: '300px',
      height: 46,
      marginRight: 20,
      background: theme.palette.background.paper,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '15px 20px'
    },
    input: {
      width: '100%',
      fontSize: 14,
      border: 0,
      outline: 0,
      background: 'transparent'
    },
    icon: {
      color: theme.palette.secondary.light,
      cursor: 'pointer'
    }
  })
)

const TextInput = forwardRef(({
onIconClick,
...props
}: IProps, ref: Ref<any>) => {
  const classes = useStyles()
  return (
    <Box className={classes.box}>
      <input className={classes.input} {...props} ref={ref} />
      <PhotoIcon className={classes.icon} onClick={onIconClick}/>
    </Box>
  )
})

export default TextInput
