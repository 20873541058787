import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import { Box, Typography } from '@material-ui/core'

interface IProps {
  children: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      width: '100%',
      height: 40,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    line: {
      width: '100%',
      height: 1,
      position: 'absolute',
      top: 20,
      left: 0,
      backgroundColor: theme.palette.divider
    },
    stamp: {
      height: 40,
      borderRadius: 20,
      border: `1px solid ${theme.palette.divider}`,
      fontSize: 14,
      fontWeight: 600,
      textAlign: 'center',
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.default,
      padding: '10px 15px',
      zIndex: 1
    }
  })
)

const TimeStamp = (props: IProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.box}>
      <div className={classes.line}/>
      <Typography className={classes.stamp}>
        {props.children}
      </Typography>
    </Box>
  )
}

export default TimeStamp
