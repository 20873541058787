import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const Title = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '27px'
    }
  })
)(Typography)

export default Title
