import jwtDecode from 'jwt-decode'
import isAfter from 'date-fns/isAfter'
import toDate from 'date-fns/toDate'

function isTokenExpired (token: string) {
  if (!token) return true
  try {
    const tokenPayload: {[key: string]: any} = jwtDecode(token)
    if (!tokenPayload || !tokenPayload.exp) return true
    return isAfter(new Date(), toDate((tokenPayload.exp - 3600) * 1000))
  } catch (e) {
    console.log('error on token decode: ', e)
    return true
  }
}

export default isTokenExpired
