import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Paginator } from 'twilio-chat/lib/interfaces/paginator'
import { Channel } from 'twilio-chat/lib/channel'
import { Client } from 'twilio-chat'

function useChannels (twilioClient: Client | null) {

  const getChatChannels = async () => {
    return twilioClient?.getSubscribedChannels()
  }

  const { status, data: channels } = useQuery(['channels'], getChatChannels, { enabled: !!twilioClient })

  return {
    channels,
    channelsStatus: status
  }
}

export default useChannels
