import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'

export default function PrivateRoute ({ children, ...rest }: RouteProps) {
  const authenticated = useSelector((state: RootState) => state.auth.loggedIn)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}
