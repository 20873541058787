import { Box, CircularProgress } from '@material-ui/core'
import React from 'react'
import ContactItem from '../../components/simpleComponents/ContactItem'
import Avatar from '../../components/simpleComponents/Avatar'
import { SearchAccountsResponseType } from '../../providers/APIClient/types'

interface IProps {
  data?: { data: SearchAccountsResponseType, [key: string]: any }[]
  status: string
  kateNumber: string
  onSelect: (index: number) => void
  selected: number
}

const ContactsListView = (props: IProps) => {
  if (props.status === 'loading') {
    return <CircularProgress />
  }
  return (
    <>
      {props.data?.map((contact, index) => {
        if (contact.data[0]) {
          return (
            <ContactItem
              key={contact.data[0].account_id}
              onClick={() => props.onSelect(index)}
              selected={props.selected === index}
            >
              <ContactItem.InfoContainer>
                <Avatar
                  initials={`${contact.data[0].first_name?.[0] || 'A'}${contact.data[0].last_name?.[0] || 'A'}`}
                  avatar={contact.data[0].profile_photo?.profile_photo_url || ''}
                  small
                />
                <Box>
                  <ContactItem.Title>{contact.data[0].first_name} {contact.data[0].last_name}</ContactItem.Title>
                  <ContactItem.Caption>{props.kateNumber}</ContactItem.Caption>
                </Box>
              </ContactItem.InfoContainer>
            </ContactItem>
          )
        }
      })}
    </>
  )
}

export default ContactsListView
