import { useContext, useEffect, useState } from 'react'
import { Client } from 'twilio-chat'
import { APIClientContext } from '../APIClient'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import isTokenExpired from '../APIClient/isTokenExpired'
import useLogoutUser from '../../hooks/useLogoutUser/useLogoutUser'

function useTwilioChatClient () {
  const [client, setClient] = useState<Client | null>(null)
  const APIClient = useContext(APIClientContext)
  const { logoutUser } = useLogoutUser()
  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn)

  useEffect(() => {
    async function initTwilioClient () {
      console.log('initTwilioClient...')
      try {
        const twilioToken = localStorage.getItem('twilioToken')
        if (!twilioToken) return
        if (isTokenExpired(twilioToken)) {
          console.log('twilio token refreshing...')

          const { data } = await APIClient.twilioAccessToken() || ''
          if (!data) return

          console.log('twilio token refreshed...')
          localStorage.setItem('twilioToken', data.access_token)

          const twilioClient = await Client.create(data.access_token)
          setClient(twilioClient)
        } else {
          const twilioClient = await Client.create(twilioToken)
          setClient(twilioClient)
        }
        console.log('Chat initialized...')
      } catch (e) {
        console.log('error on initTwilioClient:', e)
      }
    }
    if (!loggedIn) return
    initTwilioClient()

    return () => {
      console.log('twilioClient off...')
      client?.shutdown()
    }

  }, [loggedIn])

  useEffect(() => {
    async function updateTwilioToken () {
      console.log('Update chat client token...')
      const twilioToken = localStorage.getItem('twilioToken')
      if (!twilioToken) return

      if (client) {
        client?.updateToken(twilioToken)
      } else {
        const newChatClient = await Client.create(twilioToken)
        setClient(newChatClient)
      }
    }

    window.addEventListener('tokenRefreshEvent', updateTwilioToken)

    client?.on('tokenAboutToExpire', () => console.log('tokenAboutToExpire'))

    client?.on('tokenExpired', () => console.log('tokenExpired'))
    client?.on('connectionStateChanged', console.log)

    return () => {
      window.removeEventListener('tokenRefreshEvent', updateTwilioToken)

      client?.off('tokenAboutToExpire', updateTwilioToken)
      client?.off('tokenExpired', updateTwilioToken)
      client?.shutdown()
    }
  }, [client])

  return client
}

export default useTwilioChatClient
