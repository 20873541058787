import React, { memo } from 'react'
import ContactItem from '../../components/simpleComponents/ContactItem'
import Avatar from '../../components/simpleComponents/Avatar'
import { Box } from '@material-ui/core'
import getChannelName from './getChannelName'
import getChannelInitials from './getChannelInitials'
import { Channel } from 'twilio-chat/lib/channel'
import { User } from 'twilio-chat'
import { ContactsResponseType } from '../../providers/APIClient/types'

interface IChannel extends Channel {
  attributes: {
    [key: string]: any;
  }
}

interface IListItem {
  index: number
  style: {}
  data: {
    current: string
    channels: IChannel[]
    onClickFn: (id: string) => void
    contacts: ContactsResponseType
    user?: User
  }
}

const ChannelListItem = memo(
  ({
     data,
     index,
     style
   }: IListItem) => {
    const { current, channels, contacts, user } = data
    const photo = contacts.find(contact => contact.contact_account_id === channels[index].createdBy)?.profile_photo_url

    if (!channels.length) return null
    return (
      <div style={style}>
        <ContactItem.Channel
          selected={current === channels[index].sid}
          key={channels[index].sid}
          onClick={() => data.onClickFn(channels[index].sid)}
        >
          <ContactItem.InfoContainer>
            <Avatar
              initials={getChannelInitials(
                getChannelName(channels[index], user)
              )}
              avatar={photo || null}
            />
            <Box>
              <ContactItem.Title>
                {getChannelName(channels[index], user)}
              </ContactItem.Title>
            </Box>
          </ContactItem.InfoContainer>
        </ContactItem.Channel>
      </div>
    )
  })

export default ChannelListItem
