import React, { ChangeEvent, useState, FormEvent, useEffect } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import TransitionsModal from '../../components/simpleComponents/Modal/Modal'
import useLogin from '../../hooks/useLogin/useLogin'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import theme from '../../theme'
import KateLogo from '../../components/simpleComponents/Icon/KateLogo'

const useStyles = makeStyles({
  container: {
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column'
  },
  communikateLogo: {
    width: '100%',
    maxWidth: '250px',
    display: 'block',
    paddingBottom: '120px'
  },
  paper: {
    width: '100%',
    maxWidth: 450,
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2em',
    paddingTop: '4em',
    color: 'black',
    boxSizing: 'border-box'
  },
  form: {
    width: '100%'
  },
  input: {
    width: '100%',
    color: 'rgba(0, 0, 0, 0.15)'
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    textTransform: 'capitalize',
    border: '0',
    fontSize: '14px',
    borderRadius: '30px',
    padding: '10px 30px',
    width: '100%',
    cursor: 'pointer'
  },
  helpSection: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '15px 0'
  },
  styledLink: {
    margin: '5px 0',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em'
    }
  },
  typography: {
    fontSize: '10px',
    lineHeight: '10px',
    maxWidth: 450,
    padding: '2em',
    height: '100px'
  },
  message: {
    fontSize: 14
  }
})

interface FormValues {
  login: string
  password: string
}

const validate = (values: FormValues) => {
  let errors = []
  if (!values.login) {
    errors.push('login')
  }
  if (!values.password) {
    errors.push('password')
  }

  const errorMessage = `Please enter ${errors.join(', ')}`
  return {
    valid: !Object.keys(errors).length,
    errorMessage
  }
}

export default function LoginPage () {
  const classes = useStyles()
  const history = useHistory()
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [authError, setAuthError] = useState<string | null>(null)
  const { loginUser } = useLogin()
  const authenticated = useSelector((state: RootState) => state.auth.loggedIn)

  const login = async () => {
    setSubmitting(true)
    setAuthError(null)
    try {
      const email = username.trim()
      const { valid, errorMessage } = validate({ login: email, password })
      if (valid) {
        await loginUser(email, password)
        history.push('/')
      } else {
        setAuthError(errorMessage)
      }
    } catch (e) {
      console.log('error: ', e)
      setAuthError(`Wrong email or password`)
    }
    setSubmitting(false)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    login()
  }

  const handleClose = () => {
    setAuthError(null)
  }

  useEffect(() => {
    if (authenticated) {
      history.replace('/')
    }
  }, [authenticated, history])

  return (
      <Grid container justify='center' alignItems='flex-start' className={classes.container}>
        <div className={classes.paper}>
          <KateLogo style={{ marginBottom: '120px' }} />
            <form onSubmit={handleSubmit} className={classes.form}>
              <Grid container alignItems='center' direction='column'>
                <TextField
                  id='input-email'
                  label='Kate Email or Kate Number'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                  className={classes.input}
                />
                <TextField
                  id='input-password'
                  label='CommuniKate Password'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                  type='password'
                  className={classes.input}
                />
                <div className={classes.helpSection}>
                  <a className={classes.styledLink} href='https://reset2.securekate.com' target='_blank'>Login Problems?</a>
                </div>
                <Button disabled={isSubmitting} color='primary' variant='contained' className={classes.button} type='submit'>
                  Log in
                </Button>
              </Grid>
            </form>
        </div>
        <TransitionsModal title='Error' open={!!authError} handleClose={handleClose} handleSubmit={handleClose}>
          <Typography className={classes.message}>{authError}</Typography>
        </TransitionsModal>
        <Typography variant='caption' className={classes.typography}>
          Copyright 1997-2020 Parus Holdings Inc. Parus communication products and services, excluding Parus Reminders, are protected by one or more U.S and international Patent No's 9,912,628; 9,769,314; 6,775,264; 6,807,257; 7,327,723; 6,721,705; 7,076,431; 7,386,455; 7,881,941; 8,185,402; 7,516,190; 9,377,992; 9,451,084; 9,571,445; and Canadian Patent 2,321,697; and European Patent 106,671,481. Other U.S. and international patents are pending.
        </Typography>
      </Grid>
  )
}
