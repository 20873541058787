import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setAuth } from '../../redux/authentication/actions'
import { queryCache } from 'react-query'
import { setLoading } from '../../redux/loading/actions'

export default function useLogoutUser () {
  const dispatch = useDispatch()

  const logoutUser = useCallback(async () => {
    try {
      dispatch(setLoading(true))
      localStorage.clear()
      queryCache.clear()
      dispatch(setAuth({ loggedIn: false }))
      dispatch(setLoading(false))
      window.location.replace('/login')
    } catch (e) {
      console.log('Error on user logout: ', e)
    }
  }, [])

  return { logoutUser }
}
