import { Channel } from 'twilio-chat/lib/channel'
import { User } from 'twilio-chat'

interface IChannel extends Channel {
  attributes: {
    [key: string]: any;
  }
}

function getChannelName (channel?: IChannel, user?: User) {
  if (!user || !channel) return ''
  const { friendlyName, attributes } = channel
  // friendly name
  if (friendlyName) return friendlyName

  // Direct message channel created by user
  if (channel.createdBy === user.identity && attributes['direct messaging']) {
    if (!attributes.accounts) return
    let channelName = ''
    Object.keys(attributes.accounts).forEach(accountId => {
      if (accountId === user.identity) return
      const accountName = attributes.accounts[accountId]
      channelName = `${accountName?.first_name} ${accountName?.last_name}`
    })
    return channelName
  }

  return attributes.creator_name
}

export default getChannelName
