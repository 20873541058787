import { SET_USER, SetUserAction, UserState } from './types'

const INITIAL_STATE: UserState = {}

export const user = (
  state = INITIAL_STATE,
  action: SetUserAction
): UserState => {
  switch (action.type) {
    case SET_USER:
      return action.payload
    default:
      return state
  }
}
