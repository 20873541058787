import React from 'react'
import Avatar from '../../components/simpleComponents/Avatar'
import NewContact from '../../components/simpleComponents/NewContact'
import AddIcCallIcon from '@material-ui/icons/AddIcCall'

interface IProps {
  data: { [key: string]: any } | undefined
  kateNumber: string
  handleClear: () => void
  handleSearchKate: () => void
}

const KateNumberView = (props: IProps) => {
  if (props.data) {
    return (
      <NewContact.Container>
        <Avatar
          small
          avatar={props.data.profile_photo?.profile_photo_url}
          initials={`${props.data.first_name?.[0] || 'A'}${props.data.last_name?.[0] || 'A'}`}
        />
        <NewContact.TextInput
          label='CommuniKate Number'
          defaultValue={props.kateNumber}
          style={{ width: 250 }}
          inputProps={{ readOnly: true }}
        />
        <NewContact.ClearButton color='secondary' onClick={props.handleClear}/>
      </NewContact.Container>
    )
  }
  return (
    <NewContact.LargeButton color='primary' onClick={props.handleSearchKate}>
      <AddIcCallIcon style={{ marginRight: 5 }}/> Add CommuniKate Number
    </NewContact.LargeButton>
  )
}

export default KateNumberView
