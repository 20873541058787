import React, { CSSProperties, useCallback } from 'react'
import { Stream } from '@opentok/client'
// @ts-ignore
import { OTSubscriber } from 'opentok-react'

interface IProps {
  session: { [key: string]: any }
  stream: Stream,
  style: { [key: string]: any }
  setError: (error: Error) => void
  setBigStreamId: (streamId: string) => void
  checkIfStillSharing: () => void
  setStreamSrc: (src: any) => void
}

const Subscriber = ({
    session,
    stream,
    style,
    setError,
    setBigStreamId,
    checkIfStillSharing,
    setStreamSrc
}: IProps) => {
  const onSubscriberStreamDestroy = (element: any) => {
    console.log('stream destroyed', element)
    checkIfStillSharing()
  }
  const subscriberEventHandlers = {
    videoEnabled: () => {
      console.log('Subscriber video enabled')
    },
    videoDisabled: () => {
      console.log('Subscriber video disabled')
    },
    videoElementCreated: (event: any) => {
            // console.log('streams', streams)
      console.log('videoElementCreated', event)
      const targetElement: HTMLElement = event.element
      const targetStream = event.target.stream
      targetElement.setAttribute('stream-id', targetStream.id)
      targetElement.onclick = (event) => console.log('here')

      if (targetStream.videoType === 'screen') {
        setBigStreamId(targetStream.streamId)
      }
    },
    destroyed: onSubscriberStreamDestroy,
    disconnected: (element: any) => {
      console.log('subscriber disconnected', element)
    },
    audioLevelUpdated: (event: any) => {
            // console.log('stream audio level', event.audioLevel)
    }
  }

  const handleSubscriberClick = useCallback((event) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    console.log('clicked element', event.target)
  }, [])

  const onSubscribeError = (error: Error) => setError(error)

  const onSubscribe = (element: any) => {
        // console.log('Subscribe Success', element)
  }

  return (
      <div
          onDoubleClick={handleSubscriberClick}
          data-streamId={stream.streamId}
          className={`subscriber`}
          style={style}
      >
          <OTSubscriber
              key={stream.streamId}
              session={session}
              properties={{
                showControls: false
                  // insertMode: 'append',
                  // width: '100%',
                  // height: '100%'
              }}
              className={`subscriber-video-stream`}
              stream={stream}
              onSubscribe={onSubscribe}
              onError={onSubscribeError}
              eventHandlers={subscriberEventHandlers}
              style={{
                width: '100%',
                height: '100%'
              }}
          />
      </div>
  )
}

export default Subscriber
