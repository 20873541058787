import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'

const ListsDivider = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.background.paper,
      height: 3
    }
  })
)(Divider)

export default ListsDivider
