import React, { createContext, FC } from 'react'
import useAxiosClient from './useAxiosClient'
import useAPIClient from './useAPIClient'
import { AxiosResponse } from 'axios'
import {
  ContactsResponseType, CreateContactRequestType,
  GroupsResponseType,
  LoginRequestType,
  LoginResponseType,
  MembersResponseType, MessageBody, SearchAccountsRequestType, SearchAccountsResponseType, SearchByKateResponseType
} from './types'

export interface APIClientContextInterface {
  login: (req: LoginRequestType) => Promise<AxiosResponse<LoginResponseType>>
  refreshToken: (token: string | null) => Promise<AxiosResponse<any>>
  getTokboxToken: (key: string, { channelId }: { channelId: string }) => Promise<AxiosResponse<any>>
  getUser: () => Promise<AxiosResponse<any>>
  twilioAccessToken: () => Promise<AxiosResponse<any>>
  getGroups: () => Promise<AxiosResponse<GroupsResponseType>>
  getContacts: () => Promise<AxiosResponse<ContactsResponseType>>
  getGroupContacts: (groupId: string) => Promise<AxiosResponse<MembersResponseType>>
  getChannel: (key: string, { groupId, channelId }: { groupId: string, channelId: string }) => Promise<AxiosResponse<any>>
  createChannel: ({ groupId, chatType }: { groupId: string, chatType: string }) => Promise<AxiosResponse<any>>
  getPosts: (key: string, channelId: string) => Promise<AxiosResponse<any>>
  sendMessage: (messageBody: MessageBody) => Promise<AxiosResponse<any>>
  getChannelInsights: (key: string, channelId: string) => Promise<AxiosResponse<any>>
  getSearchAccounts: (key: string, req: SearchAccountsRequestType) => Promise<AxiosResponse<SearchAccountsResponseType>>
  getSearchByKateNumber: (key: string, kateNumber: string) => Promise<AxiosResponse<SearchByKateResponseType>>
  createContact: (req: CreateContactRequestType) => Promise<AxiosResponse<any>>
}

export const APIClientContext = createContext<APIClientContextInterface>({} as APIClientContextInterface)

export const APIClientProvider: FC = props => {
  const axiosClient = useAxiosClient()
  const APIClient = useAPIClient(axiosClient)
  return (
        <APIClientContext.Provider value={APIClient}>
            {props.children}
        </APIClientContext.Provider>
  )
}
