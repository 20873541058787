import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'

import EndCallButton from './EndCallButton/EndCallButton'
import ToggleAudioButton from './ToggleAudioButton/ToggleAudioButton'
import ToggleVideoButton from './ToggleVideoButton/ToggleVideoButton'
import ToggleScreenShareButton from './ToogleScreenShareButton/ToggleScreenShareButton'

import useIsUserActive from './useIsUserActive/useIsUserActive'
import useRoomState from '../../hooks/useRoomState/useRoomState'
import Fab from '@material-ui/core/Fab'
import Mic from '@material-ui/icons/Mic'
import MicOff from '@material-ui/icons/MicOff'
import Tooltip from '@material-ui/core/Tooltip'
import { Videocam, VideocamOff } from '@material-ui/icons'
import StopScreenShare from '@material-ui/icons/StopScreenShare'
import ScreenShare from '@material-ui/icons/ScreenShare'
import CallEnd from '@material-ui/icons/CallEnd'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        bottom: `${theme.sidebarMobileHeight + 3}px`
      }
    },
    fab: {
      margin: theme.spacing(1),
      background: 'transparent',
      boxShadow: 'none',
      border: '1px solid rgba(112, 112, 112, 0.5)'
    }
  })
)

interface IProps {
  roomState?: string
  quitCall: () => void
  toggleVideo: () => void
  toggleAudio: () => void
  toggleVideoSource: () => void
  videoSource: string
  video: boolean
  audio: boolean
}

export default function Controls (props: IProps) {
  const classes = useStyles()
  const isReconnecting = props.roomState === 'Reconnecting'
  const isUserActive = useIsUserActive()
  const showControls = isUserActive || props.roomState === 'Disconnected'

  return (
    <div className={clsx(classes.container, { showControls })}>
      <Tooltip
        title={props.audio ? 'Mute Audio' : 'Unmute Audio'}
        placement='top'
        PopperProps={{ disablePortal: true }}
      >
        <Fab className={classes.fab} onClick={props.toggleAudio} disabled={isReconnecting} data-cy-audio-toggle>
          {props.audio ? <Mic /> : <MicOff />}
        </Fab>
      </Tooltip>
      <Tooltip
        title={props.video ? 'Mute Video' : 'Unmute Video'}
        placement='top'
        PopperProps={{ disablePortal: true }}
      >
        <Fab className={classes.fab} onClick={props.toggleVideo} disabled={isReconnecting}>
          {props.video ? <Videocam /> : <VideocamOff />}
        </Fab>
      </Tooltip>
      {props.roomState !== 'Disconnected' && (
        <>
          <Tooltip title={'End Call'} onClick={props.quitCall} placement='top' PopperProps={{ disablePortal: true }}>
            <Fab className={classes.fab}>
              <CallEnd style={{ color: '#ff0033' }} />
            </Fab>
          </Tooltip>
        </>
      )}
    </div>
  )
}
