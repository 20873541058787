import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

const apiURL = process.env.NODE_ENV === 'development' ? '/' : process.env.REACT_APP_API_URL || ''

let isTokenRefreshing = false

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers: any = []

async function resetTokenAndReattemptRequest (error: any) {
  try {
    const { response: errorResponse } = error
    const resetToken = localStorage.getItem('kateMessangerToken') // Your own mechanism to get the refresh token to refresh the JWT token
    const xApiKey = localStorage.getItem('xApiKey')

    if (!resetToken || !xApiKey) {
        // We can't refresh, throw the error anyway
      return Promise.reject(error)
    }
        /* Proceed to the token refresh procedure
        We create a new Promise that will retry the request,
        clone all the request configuration from the failed
        request in the error object. */
    const retryOriginalRequest = new Promise(resolve => {
            /* We need to add the request retry to the queue
            since there another request that already attempt to
            refresh the token */
      addSubscriber((accessToken: string) => {
        errorResponse.config.headers.Authorization = accessToken
        resolve(axios(errorResponse.config))
      })
    })
    if (!isTokenRefreshing) {
      isTokenRefreshing = true

      const response = await axios.get(`/auth/refresh`, {
        baseURL: apiURL,
        headers: {
          'Authorization': resetToken,
          'x-api-key': xApiKey
        }
      })

      if (!response.data) {
        return Promise.reject(error)
      }
      const newToken: string = response.data.token
      localStorage.setItem('kateMessangerToken', newToken) // save the newly refreshed token for other requests to use
      localStorage.setItem('twilioToken', response.data.twilio_token) // save the newly refreshed twilio token for other requests to use
      const tokenRefreshEvent = new Event('tokenRefreshEvent')
      window.dispatchEvent(tokenRefreshEvent)
      isTokenRefreshing = false
      onAccessTokenFetched(newToken)
    }
    return retryOriginalRequest
  } catch (err) {
    console.log('refresh error')
    localStorage.clear()
    window.location.replace('/login')
    return Promise.reject(err)
  }
}

function getLocalStorageToken (key: string) {
  return localStorage.getItem(key)
}

function setLocalStorageToken (key: string, value: string) {
  return localStorage.setItem(key, value)
}

function onAccessTokenFetched (accessToken: string) {
    // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach((callback: Function) => callback(accessToken))
  subscribers = []
}

function addSubscriber (callback: Function) {
  subscribers.push(callback)
}

export function createInterceptors (client: AxiosInstance) {
  client.interceptors.request.use(
      async request => {
        const kateToken = localStorage.getItem('kateMessangerToken')
        if (kateToken) request.headers.common['Authorization'] = `${kateToken}`

        const xApiKey = localStorage.getItem('xApiKey')
        if (xApiKey) request.headers.common['x-api-key'] = `${xApiKey}`

        return request
      },
      error => Promise.reject(error)
  )

  client.interceptors.response.use(
      response => {
        if (response.data['token']) {
          localStorage.setItem('kateMessangerToken', response.data['token'])
          client.defaults.headers.common['Authorization'] = `${response.data['token']}`
        }
        if (response.data['twilio_token']) {
          localStorage.setItem('twilioToken', response.data['twilio_token'])
        }
        if (response.data['x_api_key']) {
          localStorage.setItem('xApiKey', response.data['x_api_key'])
        }
        return response
      },
      async (error) => {
        // Handle all errors except 401
        if (error.response.status !== 401) return Promise.reject(error)

        if (
            (error.response.config.url !== `${apiURL}auth/refresh`)
            && (error.response.config.url !== `${apiURL}auth/login`)
            && !isTokenRefreshing
        ) {
          return resetTokenAndReattemptRequest(error)
        }

        return Promise.reject(error)
      }
  )

  return client
}

function useAxiosClient () {
  const client = axios.create({
    baseURL: apiURL,
    timeout: 15000
  })

  const kateToken = localStorage.getItem('kateMessangerToken')
  if (kateToken) client.defaults.headers.common['Authorization'] = `${kateToken}`

  const xApiKey = localStorage.getItem('xApiKey')
  if (xApiKey) client.defaults.headers.common['x-api-key'] = `${xApiKey}`

  client.interceptors.request.use(
      async request => {
        const kateToken = localStorage.getItem('kateMessangerToken')
        if (kateToken) request.headers.common['Authorization'] = `${kateToken}`

        const xApiKey = localStorage.getItem('xApiKey')
        if (xApiKey) request.headers.common['x-api-key'] = `${xApiKey}`

        return request
      },
      error => Promise.reject(error)
  )

  client.interceptors.response.use(
      response => {
        if (response.data['token']) {
          localStorage.setItem('kateMessangerToken', response.data['token'])
          client.defaults.headers.common['Authorization'] = `${response.data['token']}`
        }
        if (response.data['twilio_token']) {
          localStorage.setItem('twilioToken', response.data['twilio_token'])
        }
        if (response.data['x_api_key']) {
          localStorage.setItem('xApiKey', response.data['x_api_key'])
        }
        return response
      },
      async (error) => {
        // Handle all errors except 401
        if (error.response.status !== 401) return Promise.reject(error)

        if (
            (error.response.config.url !== `${apiURL}auth/refresh`)
            && (error.response.config.url !== `${apiURL}auth/login`)
            && !isTokenRefreshing
        ) {
          return resetTokenAndReattemptRequest(error)
        }

        return Promise.reject(error)
      }
  )

  return client
}

export default useAxiosClient
