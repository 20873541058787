import { createStyles, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const Container = withStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flex: 1,
      justifyContent: 'space-evenly',
      alignItems: 'center',
      padding: '0 50px',
      minHeight: 600
    }
  })
)(Box)

export default Container
