import React, { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import { IconButton, Menu } from '@material-ui/core'

interface IProps {
  children: React.ReactNode
  openMenu: boolean
  setOpenMenu: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      height: 40,
      width: 40,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0
    }
  })
)

const AddChannelOrContact = (props: IProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    props.setOpenMenu()
  }

  return (
    <div>
      <IconButton
        className={classes.iconButton}
        color='primary'
        aria-label='add-channel'
        onClick={handleClick}
      >
        <AddRoundedIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={props.openMenu}
        onClose={props.setOpenMenu}
      >
        {props.children}
      </Menu>
    </div>
  )
}

export default AddChannelOrContact
