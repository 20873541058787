import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const Title = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 32,
      fontWeight: 'bold',
      marginBottom: 15,
      textTransform: 'capitalize'
    }
  })
)(Typography)

export default Title
