import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import React, { ReactNode } from 'react'
import Avatar from './Avatar'
import InfoContainer from './InfoContainer'
import Content from './Content'
import Sender from './Sender'
import Time from './Time'
import Error from './Error'
import Invite from './Invite'

enum messageStatus {
  pending = 'pending',
  error = 'error'
}

interface IProps {
  children: ReactNode,
  status?: 'pending' | 'error'
}

const MessageContainer = withStyles((theme: Theme) =>
  createStyles({
    root: (props: {status: string | undefined}) => ({
      width: '100%',
      minWidth: '100%',
      height: 'fit-content',
      display: 'flex',
      padding: '10px 20px',
      position: 'relative',
      opacity: (props.status === messageStatus.pending) ? 0.5 : 1
    })
  })
)(Box)

const Message = (props: IProps) => (
  <MessageContainer status={props.status}>
    {props.children}
  </MessageContainer>
)

Message.Avatar = Avatar
Message.InfoContainer = InfoContainer
Message.Content = Content
Message.Sender = Sender
Message.Time = Time
Message.Error = Error
Message.Invite = Invite

export default Message
