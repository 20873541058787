import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import React, { FC } from 'react'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      status: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0
      }
    })
)

const Error: FC = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.status}>
        {props.children}
    </div>
  )
}

export default Error
