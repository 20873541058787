import React from 'react'
import { IconButton } from '@material-ui/core'

interface IProps {
  fill: string
  style?: any
  onClick? (props: any): void

}

const Contacts = (props: IProps) => {
  return (
    <IconButton onClick={props.onClick} style={props.style}>
      <svg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill={props.fill} d='M7.6 0.400002C4.8096 0.400002 3.2 2.5768 3.2 5.2C3.2 6.0832 3.62188 6.97188 3.62188 6.97188C3.45228 7.06948 3.17335 7.37929 3.24375 7.92969C3.37495 8.95609 3.81993 9.2159 4.10313 9.2375C4.21112 10.1959 5.24 11.4216 5.6 11.6V13.2C4.8 15.6 0 14 0 19.6H7.2C7.2 16.2592 8.69088 14.7914 10.1469 13.9938C9.90528 13.785 9.7112 13.5336 9.6 13.2V11.6C9.9064 11.4488 10.6907 10.5412 10.9875 9.68438C10.7363 9.28598 10.5394 8.78325 10.4562 8.13125C10.3874 7.58965 10.4688 7.11933 10.6344 6.72813C10.5104 6.29693 10.4 5.7568 10.4 5.2C10.4 4.0912 10.6295 3.07535 11.0391 2.19375C10.6695 1.82255 10.1944 1.6 9.6 1.6C9.6 1.6 9.3008 0.400002 7.6 0.400002ZM16.4 0.401564C13.6096 0.400764 12 2.5768 12 5.2C12 6.0832 12.4219 6.97188 12.4219 6.97188C12.2523 7.06948 11.9734 7.37929 12.0438 7.92969C12.175 8.95609 12.6199 9.2159 12.9031 9.2375C13.0111 10.1959 14.04 11.4216 14.4 11.6V13.2C13.6 15.6 8.8 14 8.8 19.6H24C24 14 19.2 15.6 18.4 13.2V11.6C18.76 11.4216 19.7889 10.1967 19.8969 9.23906C20.1801 9.21746 20.625 8.95609 20.7563 7.92969C20.8267 7.37849 20.5477 7.06948 20.3781 6.97188C20.3781 6.97188 20.8 6.17036 20.8 5.20156C20.8 3.25916 20.0376 1.60156 18.4 1.60156C18.4 1.60156 18.1008 0.401564 16.4 0.401564Z'/>
      </svg>
    </IconButton>
  )
}

export default Contacts
