import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const CallButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 139,
      height: 46,
      fontSize: 14
    }
  })
)(Button)

export default CallButton
