import { createStyles, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const PreviewContainer = withStyles(() =>
  createStyles({
    root: {
      width: '600px',
      maxWidth: '70%'
    }
  })
)(Box)

export default PreviewContainer
