import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const Sender = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 16,
      lineHeight: '22px',
      fontWeight: 600,
      marginRight: 10
    }
  })
)(Typography)

export default Sender
