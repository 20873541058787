import ChatWindow from '../../components/simpleComponents/ChatWindow'
import Empty from '../../components/simpleComponents/ChatWindow/Empty'
import ChatMessagesView from './ChatMessagesView'
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { TwilioChatClientContext } from '../../providers/TwilioChat'
import { useMutation, useQuery } from 'react-query'
import { useParams, useHistory } from 'react-router-dom'
import ChatFooter from '../../components/simpleComponents/ChatFooter'
import { Message } from 'twilio-chat/lib/message'
import LoadingView from './LoadingView'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import { ITwilioMessage } from '../../types'
import { APIClientContext } from '../../providers/APIClient'

const MessagesList: FC = () => {
  const APIClient = useContext(APIClientContext)
  const { roomId } = useParams()
  const history = useHistory()
  const profilePhoto = useSelector((state: RootState) => state.user.profile_photo)
  const userAccountId = useSelector((state: RootState) => state.user.account_id)
  const chatClient = useContext(TwilioChatClientContext)
  const textInputRef = useRef<HTMLInputElement>(null)

  const [allMessages, setAllMessages] = useState<(Message | { [key: string]: any })[]>([])
  const [messagesLoading, setMessagesLoading] = useState(true)

  const { data: contactsRes } = useQuery('contacts', APIClient.getContacts)

  const getChannel = async () => {
    if (roomId && chatClient) return chatClient.getChannelBySid(roomId)
    // chat client - get channel by sid number
    return Promise.resolve(null)
  }
  const { data: channel, status: channelStatus } = useQuery(['channel', roomId], getChannel, { enabled: !!chatClient })

  const getMessages = async () => {
    setMessagesLoading(true)
    try {
      if (!roomId || !chatClient || !channel) return Promise.resolve(null)
      let messages = await channel.getMessages()
      messages.items = messages.items.filter(i => !!i.body)
      return setAllMessages(messages.items)
    } catch (e) {
      console.log('Error on getMessages', e)
    } finally {
      setMessagesLoading(false)
    }
  }

  const { status } = useQuery(['messages', roomId], getMessages, { enabled: !!(roomId && channelStatus === 'success') })

  const [errors, setErrors] = useState<(Message | { [key: string]: any })[]>([])
  const [mutate] = useMutation(APIClient.sendMessage, {
    onError: () => {
      setAllMessages((prevState) => {
        let lastMsg = prevState[prevState.length - 1]
        lastMsg.attributes.status = 'error'
        setErrors([...errors, lastMsg])
        return [...prevState.slice(0, prevState.length - 1), lastMsg]
      })
    }
  })

  useEffect(() => {
    if (!textInputRef.current) return
    textInputRef.current.focus()
  }, [textInputRef, roomId])

  useEffect(() => {
    if (!channel) return

    function appendMessageToTheList (message: ITwilioMessage) {
      if (message.author !== userAccountId) setAllMessages(prevState => ([...prevState, message]))
      if (message.author === userAccountId) {
        // find index of pending message that has been sent successfully
        const messageToUpdateIndex = [...allMessages].findIndex((msg, index) => {
          return (msg.attributes.body === message.attributes.body) && (['pending', 'error'].includes(msg.attributes.status))
        })

        // update message
        setAllMessages(prevState => {
          prevState[messageToUpdateIndex] = message
          return prevState
        })
      }
    }

    channel.on('messageAdded', appendMessageToTheList)
    return () => {
      channel.off('messageAdded', appendMessageToTheList)
    }
  }, [channel, chatClient, allMessages])

  const sendMessage = (text: string) => {
    const messageId = Date.now()
    const pendingMessage = {
      attributes: {
        post_id: messageId,
        status: 'pending'
      },
      author: chatClient?.user.identity || '',
      body: text,
      timestamp: new Date()
    }

    setAllMessages((prevState: any) => ([
      ...prevState,
      pendingMessage
    ]))

    const channelAttributes: {[key: string]: any} = channel?.attributes || {}
    if (channelAttributes && channelAttributes.group_id) {
      return mutate({
        group_id: channelAttributes.group_id.toString(),
        text: text
      })
    }

    return mutate({
      channel: roomId,
      text: text
    })

  }

  const resendMessage = (messageId: string) => {
    const message = allMessages.find(msg => msg.attributes.post_id === messageId)

    if (message && message.attributes.status === 'error') {
      setAllMessages((prevState) => {
        let resendMsg = message
        resendMsg.attributes.status = 'pending'
        return [...prevState.filter(msg => msg.attributes.post_id !== resendMsg.attributes.post_id), resendMsg]
      })
      setErrors((prevState) => (prevState.filter(msg => msg.attributes.post_id !== messageId)))
      const channelAttributes: {[key: string]: any} = channel?.attributes || {}

      if (channelAttributes && channelAttributes.group_id) {
        return mutate({
          group_id: channelAttributes.group_id.toString(),
          text: message.body
        })
      }

      return mutate({
        channel: roomId,
        text: message.body
      })
    }
  }

  const onMessageInputEnterKey = useCallback((e) => {
    if (e.keyCode !== 13 || !channel || !e.target.value) return
    sendMessage(e.target.value)
    e.target.value = ''
  }, [roomId, channel])

  const onJoinCall = useCallback(() => {
    history.push(`/video/${roomId}`)
  }, [history, roomId])

  if (messagesLoading || channelStatus !== 'success') {
    return (
        <>
          <ChatWindow>
            <LoadingView history={history} />
          </ChatWindow>
          <ChatFooter>
            <ChatFooter.TextInput
                ref={textInputRef}
                onIconClick={() => console.log('photo icon')}
                placeholder='Type your message'
                onKeyDown={onMessageInputEnterKey}
                disabled
            />
          </ChatFooter>
        </>
    )
  }

  return (
    <>
      <ChatWindow>
        {
          (allMessages && allMessages.length > 0 && status === 'success') &&
          (channel && channelStatus === 'success') && (
            <ChatMessagesView
              messages={allMessages}
              user={chatClient?.user}
              contacts={contactsRes?.data}
              profilePhoto={profilePhoto}
              onResend={resendMessage}
              onJoinCall={onJoinCall}
            />
        )}
        {
          (channel && allMessages && allMessages.length === 0 && status === 'success') &&
          (channelStatus === 'success') && (
              <Empty />
          )
        }
      </ChatWindow>

      <ChatFooter>
        <ChatFooter.TextInput
          ref={textInputRef}
          onIconClick={() => console.log('photo icon')}
          placeholder='Type your message'
          onKeyDown={onMessageInputEnterKey}
        />
      </ChatFooter>
      </>
  )
}

export default MessagesList
