import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Toolbar from '@material-ui/core/Toolbar'
import Menu from './Menu/Menu'

import { useParams } from 'react-router-dom'
import useRoomState from '../../hooks/useRoomState/useRoomState'
import useVideoContext from '../../hooks/useVideoContext/useVideoContext'
import FlipCameraButton from './FlipCameraButton/FlipCameraButton'
import useTwilioToken from '../../hooks/useTwilioToken/useTwilioToken'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import { setLoading } from '../../redux/loading/actions'
import { Button, Typography } from '@material-ui/core'
import Controls from '../Controls/Controls'
import CopyIcon from '@material-ui/icons/FileCopy'
import CopyToClipboard from 'react-copy-to-clipboard'
import AdditionalVideoControls from '../AdditionalVideoControls'
import App from '../../App'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0
      // display: 'flex',
        // boxShadow: ''
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto'
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em'
      }
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200
    },
    loadingSpinner: {
      marginLeft: '1em'
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600
    },
    joinButton: {
      margin: '1em'
    },
    error: {
      maxWidth: '400px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  })
)

interface IProps {
  roomState?: string
  error?: Error | null
  toggleVideo: () => void
  toggleAudio: () => void
  toggleVideoSource: () => void
  videoEnabled: boolean
  audioEnabled: boolean
  videoSource: string
  quitCall: () => void
  screenSharingAvailable: boolean
}

export default function Index (props: IProps) {
  const classes = useStyles()
  const loading = useSelector((state: RootState) => state.loading)

  const {
    toggleVideo,
    toggleAudio,
    toggleVideoSource,
    videoEnabled,
    audioEnabled,
    videoSource,
    quitCall,
    screenSharingAvailable
  } = props

  return (
    <AppBar className={classes.container} position='static'>
      <Toolbar className={classes.toolbar}>
        {/*{(loading || props.roomState === 'Connecting')*/}
        {/*  ? <CircularProgress className={classes.loadingSpinner} />*/}
        {/*  : props.error*/}
        {/*      ? <Typography className={classes.error} variant='body2' color='error'>Error: {props.error.message}</Typography>*/}
        {/*      : <Typography variant='h5' color='secondary'>Session status: {props.roomState}</Typography>*/}
        {/*}*/}
          <CopyToClipboard text={window.location.href}>
              <Button color='primary' size='small'>
                  <CopyIcon/>Copy Invite link
              </Button>
          </CopyToClipboard>
          <Controls
              toggleVideo={toggleVideo}
              toggleAudio={toggleAudio}
              toggleVideoSource={toggleVideoSource}
              video={videoEnabled}
              audio={audioEnabled}
              videoSource={videoSource}
              quitCall={quitCall}
          />
          <div className={classes.rightButtonContainer}>
              <AdditionalVideoControls
                  videoSource={videoSource}
                  toggleVideoSource={toggleVideoSource}
                  screenSharingAvailable={screenSharingAvailable}
              />
          </div>
      </Toolbar>
    </AppBar>
  )
}
