import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Badge, Box } from '@material-ui/core'
import MuiAvatar from '@material-ui/core/Avatar'
import React from 'react'
import { indigo } from '@material-ui/core/colors'

interface IChannelAvatar {
  members?: number
  initials: string
  avatar: string | null
  extraLarge?: boolean
  small?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    defaultAvatar: {
      width: 50,
      height: 50,
      marginRight: -10,
      backgroundColor: indigo[500],
      fontSize: 18,
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    largeAvatar: {
      width: 80,
      height: 80,
      marginRight: -10
    },
    smallAvatar: {
      width: 40,
      height: 40
    },
    box: {
      marginRight: 25
    }
  })
)

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      border: `1px solid white`,
      height: 30,
      width: 30,
      borderRadius: 15,
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      bottom: 5,
      right: -5
    }
  })
)(Badge)

const Avatar = (props: IChannelAvatar) => {
  const classes = useStyles()
  const avatarClass = props.extraLarge
    ? `${classes.defaultAvatar} ${classes.largeAvatar}`
    : props.small
      ? `${classes.defaultAvatar} ${classes.smallAvatar}`
      : classes.defaultAvatar

  return (
    <Box className={classes.box}>
      {props.members && props.members > 1
        ? <StyledBadge
          badgeContent={`+${props.members - 1}`}
          anchorOrigin={{
            vertical: 'bottom', horizontal: 'right'
          }}>
          <MuiAvatar className={`${classes.defaultAvatar} ${classes.smallAvatar}`} alt={props.initials} src={props.avatar || ''}>
            {props.initials}
          </MuiAvatar>
        </StyledBadge>
        : <MuiAvatar className={avatarClass} alt={props.initials} src={props.avatar || ''}>
          {props.initials}
        </MuiAvatar>
      }
    </Box>
  )
}

export default Avatar
