import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { setUser } from './actions'
import { useContext } from 'react'
import { APIClientContext } from '../../providers/APIClient'

const useUser = () => {
  const APIClient = useContext(APIClientContext)
  const dispatch = useDispatch()

  const { status: userStatus, data: userRes } = useQuery('user', APIClient.getUser)
  if (userStatus === 'success') {
    dispatch(setUser(userRes?.data))
  }
  return userRes?.data
}

export default useUser
