import { SET_AUTH, SetAuthAction } from './types'

const INITIAL_STATE: { loggedIn: boolean, refreshingTokens: boolean} = {
  loggedIn: false,
  refreshingTokens: false
}

export const auth = (
  state = INITIAL_STATE,
  action: SetAuthAction
) => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
