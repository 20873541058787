import { Channel } from 'twilio-chat/lib/channel'
import { User } from 'twilio-chat'
import { ContactsResponseType } from '../../providers/APIClient/types'

interface IChannel extends Channel {
  attributes: {
    [key: string]: any;
  }
}

function getKateNumber (channel?: IChannel, user?: User, contacts?: ContactsResponseType) {
  if (!user || !channel) return ''
  const { attributes } = channel

  if (attributes['direct messaging']) {
    if (!attributes.accounts) return ''
    let kateNumber = ''
    Object.keys(attributes.accounts).forEach(accountId => {
      if (accountId === user.identity) return
      kateNumber = contacts?.find(contact => contact.contact_account_id === accountId)?.kate_number || ''
    })
    return kateNumber
  }

  return ''
}

export default getKateNumber
