import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import MuiAvatar from '@material-ui/core/Avatar'
import React from 'react'
import { indigo } from '@material-ui/core/colors'

interface IAvatar {
  initials: string
  avatar: string | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    defaultAvatar: {
      width: 20,
      height: 20,
      backgroundColor: indigo[500],
      fontSize: 10,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      marginRight: 6
    }
  })
)

const Avatar = (props: IAvatar) => {
  const classes = useStyles()

  return (
    <MuiAvatar className={classes.defaultAvatar} alt={props.initials} src={props.avatar || ''}>
      {props.initials}
    </MuiAvatar>
  )
}

export default Avatar
