import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import { Box } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'flex-end'
    },
    default: {
      width: 4,
      backgroundColor: grey[400],
      marginRight: 4
    },
    primary: {
      backgroundColor: theme.palette.primary.main
    }
  })
)

interface IProps {
  strength: number
}

const AudioStrength = (props: IProps) => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      {[2, 4, 6, 8, 10, 12, 14, 16, 19].map((height, index) =>
        <Box key={index} className={`${classes.default} ${index < props.strength ? classes.primary : ''}`} style={{ height }}/>
      )}
    </Box>
  )
}

export default AudioStrength
