export const SET_USER = 'SET_USER'

export interface SetUserAction {
  type: typeof SET_USER,
  payload: { [key: string]: any }
}

export interface UserState {
  [key: string]: any
}
