import WaitingRoom from '../../components/simpleComponents/WaitingRoom'
import { Button, Typography } from '@material-ui/core'
import CopyIcon from '@material-ui/icons/FileCopy'
import React, { ReactNode } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import KateLogo from '../../components/simpleComponents/Icon/KateLogo'

interface IProps {
  onJoin: () => void
  video: boolean
  toggleVideo: () => void
  audio: boolean
  toggleAudio: () => void
  children: ReactNode
  volume: number
  channelName?: string
  streamsNumber?: number
  copyText: string
  guests?: string[]
  guestsAvatars?: { [key: string]: string }
  sendInviteMessage: () => void
  onKateIconClick: () => void
}

const WaitingRoomView = (props: IProps) => {
  return (
    <WaitingRoom>
      <WaitingRoom.Header>
        <div onClick={props.onKateIconClick} style={{ cursor: 'pointer' }}>
          <KateLogo width={124} />
        </div>
        <CopyToClipboard text={props.copyText}>
          <Button color='primary' size='small'>
            <CopyIcon/>Copy Invite link
          </Button>
        </CopyToClipboard>
      </WaitingRoom.Header>
      <WaitingRoom.Container>
        <WaitingRoom.PreviewContainer>
          {props.children}
        </WaitingRoom.PreviewContainer>
        <WaitingRoom.InfoContainer>
          <Typography variant='h6'>Video Call with {props.channelName || '...'}</Typography>
          <WaitingRoom.AvatarsContainer>
            {!props.guests || props.guests.length <= 4
              ? props.guests?.map((name, index) =>
                <WaitingRoom.Avatar
                  key={index}
                  initials={`${name.split(' ')[0]?.[0] || ''}${name.split(' ')[1]?.[0] || ''}`}
                  avatar={props.guestsAvatars?.[name] || ''}
                />
              )
              : <>
                <WaitingRoom.Avatar
                  initials={`${props.guests[0].split(' ')[0]?.[0] || ''}${props.guests[0].split(' ')[1]?.[0] || ''}`}
                  avatar={props.guestsAvatars?.[props.guests[0] || ''] || ''}
                />
                <WaitingRoom.Avatar
                  initials={`${props.guests[1].split(' ')[0]?.[0] || ''}${props.guests[1].split(' ')[1]?.[0] || ''}`}
                  avatar={props.guestsAvatars?.[props.guests[1] || ''] || ''}
                />
                <WaitingRoom.Avatar
                  initials={`${props.guests[2].split(' ')[0]?.[0] || ''}${props.guests[2].split(' ')[1]?.[0] || ''}`}
                  avatar={props.guestsAvatars?.[props.guests[2] || ''] || ''}
                />
                <WaitingRoom.Avatar initials={`+${(props.guests.length || 0) - 3}`} avatar=''/>
              </>
            }
          </WaitingRoom.AvatarsContainer>
          <Typography variant='body2'>
            {props.guests?.length
              ? (props.guests.length <= 3
                  ? `${props.guests.map(name => ` ${name.split(' ')[0]}`)} in the call`
                  : `${props.guests[0].split(' ')[0]} ${props.guests[1].split(' ')[0]} and ${props.guests.length - 2} others in the call`
              )
              : 'No one in the call yet'}
          </Typography>
          <Button color='primary' variant='contained' onClick={() => {
            props.sendInviteMessage()
            props.onJoin()
          }}>Join Now</Button>
          <div>
            <WaitingRoom.SwitchContainer>
              Camera <WaitingRoom.StyledSwitch value={props.video} onChange={props.toggleVideo} />
            </WaitingRoom.SwitchContainer>
            <WaitingRoom.SwitchContainer>
              Audio <WaitingRoom.AudioStrength
                strength={props.volume} />
                <WaitingRoom.StyledSwitch
                    value={props.audio}
                    onChange={props.toggleAudio}
                />
            </WaitingRoom.SwitchContainer>
          </div>
        </WaitingRoom.InfoContainer>
      </WaitingRoom.Container>
    </WaitingRoom>
  )
}

export default WaitingRoomView
