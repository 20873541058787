import SideBar from '../../components/simpleComponents/SideBar'
import KateIcon from '../../components/simpleComponents/Icon/KateIcon'
import { Box, ListItemText, MenuItem } from '@material-ui/core'
import Channels from '../../components/simpleComponents/Icon/Channels'
import Contacts from '../../components/simpleComponents/Icon/Contacts'
import Settings from '../../components/simpleComponents/Icon/Settings'
import Logout from '../../components/simpleComponents/Icon/Logout'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ConversationsList from '../../components/simpleComponents/ConversationsList'
import SearchInput from '../../components/simpleComponents/Search/SearchInput'
import AddToGroup from '../../components/simpleComponents/Icon/AddToGroup'
import useLogoutUser from '../../hooks/useLogoutUser/useLogoutUser'
import useUser from '../../hooks/useUser/useUser'
import AddContact from '../AddContact'
import { useDispatch } from 'react-redux'
import { setModal } from '../AddContact/redux/actions'
import theme from '../../theme'

interface IProps {
  children: React.ReactNode
  list: React.ReactNode
}

const AppView = (props: IProps) => {
  const { logoutUser } = useLogoutUser()
  const history = useHistory()
  const dispatch = useDispatch()
  const listType = history.location.hash.substr(1)
  useUser()
  const [openMenu, setOpenMenu] = useState(false)

  const handleContacts = useCallback(() => {
    history.push(`${history.location.pathname}#contacts`)
  }, [history])

  const handleChannels = useCallback(() => {
    history.push(`${history.location.pathname}#channels`)
  }, [])

  const handleSettings = useCallback(() => {
    history.push('/settings')
  }, [history])

  // search
  const searchValue = history.location.search.substr(1)

  const handleSearchFocus = useCallback(() => {
    history.replace(`${history.location.pathname}${history.location.search}#${listType}`)
  }, [history, listType])

  const handleSearchChange = useCallback(event => {
    history.replace(`${history.location.pathname}?${event.target.value}#${listType}`)
  }, [history, listType])

  const handleSearchClear = useCallback(() => {
    history.replace(`${history.location.pathname}#${listType}`)
  }, [history, listType])

  const handleGoToMainPage = useCallback(() => {
    history.push(`/`)
  }, [history])

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex' }}>
      <SideBar>
        <KateIcon onClick={handleGoToMainPage} />
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Channels
            fill={
              listType.toLowerCase() === 'channels'
                  ? theme.palette.primary.main
                  : '#bebebe'
            }
            style={{ marginBottom: 20 }}
            onClick={handleChannels}
          />
          <Contacts
            fill={
              listType.toLowerCase() === 'contacts'
                  ? theme.palette.primary.main
                  : '#bebebe'
            }
            onClick={handleContacts}
          />
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Settings
            fill={
              listType.toLowerCase() === 'settings'
                  ? theme.palette.primary.main
                  : '#bebebe'
            }
            onClick={handleSettings}
          />
          <Logout
            fill='#bebebe'
            style={{ marginTop: 10 }}
            onClick={logoutUser}
          />
        </Box>
      </SideBar>
      <ConversationsList>
        <ConversationsList.HeaderContainer>
          <ConversationsList.Title>
            {listType}
          </ConversationsList.Title>
          <ConversationsList.ActionsContainer>
            <SearchInput
              onFocus={handleSearchFocus}
              onChange={handleSearchChange}
              onClear={handleSearchClear}
              value={searchValue}
            />
            <ConversationsList.AddChannelOrContact
              openMenu={openMenu}
              setOpenMenu={() => setOpenMenu(!openMenu)}
            >
              <MenuItem onClick={() => {
                setOpenMenu(false)
                dispatch(setModal(true))
              }}>
                <AddToGroup fill={theme.palette.primary.main} style={{ marginRight: 15 }} />
                <ListItemText primary='New Contact' />
              </MenuItem>
              <MenuItem onClick={() => console.log('add group')}>
                <AddToGroup fill={theme.palette.primary.main} style={{ marginRight: 15 }} />
                <ListItemText primary='New Group' />
              </MenuItem>
            </ConversationsList.AddChannelOrContact>
          </ConversationsList.ActionsContainer>
        </ConversationsList.HeaderContainer>
        {props.list}
      </ConversationsList>
      {props.children}
      <AddContact />
    </div>
  )
}

export default AppView
