import { SET_LOADING, SetLoadingAction } from './types'

const INITIAL_STATE: boolean = true

export const loading = (
  state = INITIAL_STATE,
  action: SetLoadingAction
): boolean => {
  switch (action.type) {
    case SET_LOADING:
      return action.payload
    default:
      return state
  }
}
