import React, { FC, createContext } from 'react'
import useTwilioChatClient from './useTwilioChatClient'
import Client from 'twilio-chat'

export const TwilioChatClientContext = createContext<Client | null>(null)

export const TwilioChatProvider: FC = props => {
  const twilioChatClient = useTwilioChatClient()
  return (
    <TwilioChatClientContext.Provider value={twilioChatClient}>
      {props.children}
    </TwilioChatClientContext.Provider>
  )
}
