import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { useEffect, useRef } from 'react'
import TimeStamp from './TimaStamp'

interface IProps {
  children: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      width: '100%',
      height: 'calc(100% - 136px)',
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto'
    }
  })
)

const ChatWindow = (props: IProps) => {
  const classes = useStyles()

  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  const scrollToBottom = () => {
    if (scrollContainerRef && scrollContainerRef.current) {
      window.requestAnimationFrame(() => {
        if (scrollContainerRef && scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight + 100
        }
      })
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [props.children])

  return (
    <div className={classes.box} ref={scrollContainerRef}>
      {props.children}
    </div>
  )
}

ChatWindow.TimeStamp = TimeStamp

export default ChatWindow
