export const SET_AUTH = 'SET_AUTH'

export interface SetAuthAction {
  type: typeof SET_AUTH,
  payload: {
    loggedIn?: boolean,
    refreshingTokens?: boolean
  }
}

export type AuthTypes = SetAuthAction
