import { createStyles, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const InfoContainer = withStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      height: 300
    }
  })
)(Box)

export default InfoContainer
