import React from 'react'
import { IconButton } from '@material-ui/core'

interface IProps {
  fill: string
  style?: any
  onClick? (props: any): void
}

const Channels = (props: IProps) => {
  return (
    <IconButton onClick={props.onClick} style={props.style}>
      <svg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fill={props.fill} d='M9.39997 0.400002C4.53998 0.400002 0.599976 3.9816 0.599976 8.4C0.599976 10.67 1.64519 12.7143 3.3156 14.1703C3.07516 14.9527 2.57516 15.7314 1.65935 16.425C1.65874 16.4255 1.6584 16.4261 1.65779 16.4266C1.57064 16.46 1.49794 16.5229 1.45227 16.6043C1.40659 16.6857 1.39083 16.7805 1.40771 16.8723C1.42459 16.9641 1.47304 17.0471 1.54468 17.107C1.61632 17.1668 1.70663 17.1997 1.79998 17.2C1.82835 17.1999 1.85664 17.1967 1.88435 17.1906C3.43205 17.1852 4.75298 16.525 5.80466 15.6969C6.90334 16.1451 8.11776 16.4 9.39997 16.4C14.26 16.4 18.2 12.8184 18.2 8.4C18.2 3.9816 14.26 0.400002 9.39997 0.400002ZM6.19998 6H12.6C13.0416 6 13.4 6.3584 13.4 6.8C13.4 7.2416 13.0416 7.6 12.6 7.6H6.19998C5.75837 7.6 5.39997 7.2416 5.39997 6.8C5.39997 6.3584 5.75837 6 6.19998 6ZM6.19998 9.2H11C11.4416 9.2 11.8 9.5584 11.8 10C11.8 10.4416 11.4416 10.8 11 10.8H6.19998C5.75837 10.8 5.39997 10.4416 5.39997 10C5.39997 9.5584 5.75837 9.2 6.19998 9.2ZM19.6218 10.1328C18.797 14.2856 15.0752 17.5148 10.464 17.95C11.6224 18.9684 13.2272 19.6 15 19.6C15.8448 19.6 16.649 19.4525 17.3875 19.1922C18.3693 19.8781 19.5577 20.3867 20.9187 20.3906C20.9454 20.3965 20.9726 20.3997 21 20.4C21.0938 20.4 21.1846 20.367 21.2565 20.3068C21.3285 20.2466 21.377 20.163 21.3936 20.0706C21.4101 19.9783 21.3937 19.8831 21.3472 19.8016C21.3007 19.7202 21.227 19.6576 21.139 19.625C20.4063 19.0689 19.9362 18.4576 19.6547 17.8344C20.7337 16.832 21.4 15.4857 21.4 14C21.4 12.4984 20.721 11.1384 19.6218 10.1328Z'/>
      </svg>
    </IconButton>
  )
}

export default Channels
