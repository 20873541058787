import React, { CSSProperties, FC } from 'react'

type ShareScreenType = {
  height?: number,
  width?: number,
  fill?: string
  style?: CSSProperties
}

const ShareScreenIcon: FC<ShareScreenType> = ({
height = 34,
width = 39,
fill = '#1D70B6',
style
}) => {
  return (
        <svg width={width} height={height} viewBox='0 0 39 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='1.365' y='1.365' width='36.27' height='30.42' rx='2.535' stroke={fill} strokeWidth='2.73'/>
            <rect x='18.0771' y='9.75' width='2.73' height='15.6' rx='1.365' fill={fill}/>
            <rect x='17.5254' y='9.73047' width='2.73' height='10.6305' rx='1.365' transform='rotate(-45 17.5254 9.73047)' fill={fill}/>
            <rect x='19.416' y='7.80005' width='2.73' height='10.6341' rx='1.365' transform='rotate(45 19.416 7.80005)' fill={fill}/>
        </svg>
  )
}

export default ShareScreenIcon
