import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const SideBar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      minWidth: 60,
      height: '100vh',
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px 0 0'
    }
  })
)(Box)

export default SideBar
