import React, { FC, useCallback, useContext, useEffect } from 'react'
import { format } from 'date-fns'
import { Box } from '@material-ui/core'
import { useQuery } from 'react-query'
import { useParams, useHistory } from 'react-router-dom'
import ContactItem from '../../components/simpleComponents/ContactItem'
import Avatar from '../../components/simpleComponents/Avatar'
import getChannelName from './getChannelName'
import getChannelInitials from './getChannelInitials'
import { TwilioChatClientContext } from '../../providers/TwilioChat'
import CircularProgress from '@material-ui/core/CircularProgress'
import getKateNumber from './getKateNumber'
import { Message } from 'twilio-chat/lib/message'
import useChannels from './useChannels'
import useChannelsLastMessage from './useChannelsLastMessage'
import useChannelsUnreadMessagesCount from './useChannelsUnreadMessagesCount'
import { APIClientContext } from '../../providers/APIClient'

interface IMessage extends Message {
  attributes: {
    [key: string]: any
    ['first name']?: string
  }
}

const ChannelsList: FC = () => {
  const APIClient = useContext(APIClientContext)
  const history = useHistory()
  const { roomId } = useParams()
  const twilioClient = useContext(TwilioChatClientContext)
  const { data: contactsRes } = useQuery('contacts', APIClient.getContacts)
  const { channels, channelsStatus } = useChannels(twilioClient)
  const { channelsLastMessage, channelsLastMessageStatus } = useChannelsLastMessage(channels)
  const channelsUnreadMessagesCount = useChannelsUnreadMessagesCount(roomId)

  const getMembersCount = useCallback(() => {
    if (channels) return Promise.all(channels.items.map((channel) => channel.getMembersCount()))
    return Promise.resolve(null)
  }, [channels])

  const { data: membersCountRes } = useQuery(
      ['members', channelsStatus],
      getMembersCount,
      { enabled: channelsStatus === 'success' }
    )

  useEffect(() => {
    if (channels && channels.items.length && channels.items[0] && (!roomId || roomId.length < 15)) {
      history.replace(`/chat/${channels.items[0].sid}#channels`)
    }
  }, [channels, history, roomId])

  if (
      channelsStatus === 'success' &&
      channelsLastMessageStatus === 'success' && channels
  ) {
    return (
      <>
        {!!channels.items.length &&
        channels.items.map((channel, index) => {
          const photo = contactsRes?.data.find((contact) => contact.contact_account_id === channel.createdBy)?.profile_photo_url
          const lastMessage: IMessage | undefined = channelsLastMessage ? channelsLastMessage[channel.sid] : undefined
          let lastAuthor
          if (lastMessage) {
            const { attributes } = lastMessage.attributes
            const key: keyof typeof attributes = 'first name'
            lastAuthor = lastMessage?.attributes[key]
          }
          return (
              <ContactItem.Channel
                selected={roomId === channel.sid}
                key={channel.sid}
                onClick={() => history.push(`/chat/${channel.sid}#channels`)}
               >
              <ContactItem.InfoContainer>
                <Avatar
                  initials={getChannelInitials(
                    getChannelName(channel, twilioClient?.user)
                    )}
                  avatar={photo || null}
                  members={membersCountRes?.[index] && membersCountRes?.[index] - 1}
                />
                <Box>
                  <ContactItem.Title>
                    {getChannelName(channel, twilioClient?.user)}
                    </ContactItem.Title>
                    <ContactItem.Caption>
                      {lastMessage ? `${lastAuthor}: ${lastMessage?.body}` : getKateNumber(channel, twilioClient?.user, contactsRes?.data)}
                    </ContactItem.Caption>
                </Box>
              </ContactItem.InfoContainer>
                <ContactItem.AsideContainer>
                  <ContactItem.Caption>{lastMessage ? format(lastMessage.timestamp, 'H:mm aaa') : ''}</ContactItem.Caption>
                  {
                    (channelsUnreadMessagesCount && !!channelsUnreadMessagesCount[channel.sid]) &&
                    <ContactItem.NewMessages>
                      {channelsUnreadMessagesCount[channel.sid] || ''}
                    </ContactItem.NewMessages>
                  }
                </ContactItem.AsideContainer>
            </ContactItem.Channel>
          )
        })}
      </>
    )
  }

  if (channelsStatus === 'error') {
    return (
      <ContactItem.Channel>
        <ContactItem.InfoContainer>
          <ContactItem.Title>Ups, there's been some error</ContactItem.Title>
        </ContactItem.InfoContainer>
      </ContactItem.Channel>
    )
  }

  return (
    <div style={{ width: '100%', padding: '20px', display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  )
}

export default ChannelsList
