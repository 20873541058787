import React from 'react'
import { IconButton } from '@material-ui/core'

interface IProps {
  fill: string
  style?: any
  onClick? (props: any): void

}

const Settings = (props: IProps) => {
  return (
    <IconButton onClick={props.onClick} style={props.style}>
      <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill={props.fill}
          d='M6.74188 0.666656C6.34271 0.666656 6.00149 0.948818 5.92482 1.34048L5.6465 2.76952C4.96253 3.02816 4.3301 3.38949 3.77475 3.84537L2.40431 3.37336C2.02681 3.24336 1.61147 3.39946 1.41147 3.74446L0.154962 5.92219C-0.0442046 6.26802 0.0282823 6.70707 0.329116 6.96874L1.42775 7.92414C1.37061 8.27527 1.33335 8.63273 1.33335 8.99999C1.33335 9.36725 1.37061 9.72471 1.42775 10.0758L0.329116 11.0312C0.0282823 11.2929 -0.0442046 11.732 0.154962 12.0778L1.41147 14.2555C1.61064 14.6014 2.02681 14.7574 2.40431 14.6282L3.77475 14.1562C4.32992 14.6118 4.96284 14.9719 5.6465 15.2305L5.92482 16.6595C6.00149 17.0512 6.34271 17.3333 6.74188 17.3333H9.25815C9.65732 17.3333 9.99854 17.0512 10.0752 16.6595L10.3535 15.2305C11.0375 14.9718 11.6699 14.6105 12.2253 14.1546L13.5957 14.6266C13.9732 14.7566 14.3886 14.6014 14.5886 14.2555L15.8451 12.0762C16.0442 11.7303 15.9717 11.2929 15.6709 11.0312L14.5723 10.0758C14.6294 9.72471 14.6667 9.36725 14.6667 8.99999C14.6667 8.63273 14.6294 8.27527 14.5723 7.92414L15.6709 6.96874C15.9717 6.70707 16.0442 6.26802 15.8451 5.92219L14.5886 3.74446C14.3894 3.39862 13.9732 3.24257 13.5957 3.37173L12.2253 3.84374C11.6701 3.38815 11.0372 3.02804 10.3535 2.76952L10.0752 1.34048C9.99854 0.948818 9.65732 0.666656 9.25815 0.666656H6.74188ZM8.00001 5.66666C9.84085 5.66666 11.3333 7.15916 11.3333 8.99999C11.3333 10.8408 9.84085 12.3333 8.00001 12.3333C6.15918 12.3333 4.66668 10.8408 4.66668 8.99999C4.66668 7.15916 6.15918 5.66666 8.00001 5.66666Z'
        />
      </svg>
    </IconButton>
  )
}

export default Settings
