import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const Time = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 12,
      lineHeight: '16px',
      color: theme.palette.text.secondary
    }
  })
)(Typography)

export default Time
