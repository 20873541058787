import { createStyles, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const AvatarsContainer = withStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
)(Box)

export default AvatarsContainer
