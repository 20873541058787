import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Modal, Backdrop, Fade, Typography, Card, CardContent, CardActions, Button } from '@material-ui/core'

interface IProps {
  open: boolean
  children: React.ReactNode
  handleClose? (): void
  handleSubmit (): void
  submit?: string
  cancel?: boolean
  cancelLabel?: string
  disabled?: boolean
  title: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    card: {
      backgroundColor: theme.palette.background.default,
      outline: 'none'
    },
    content: {
      padding: '30px 20px 10px 30px'
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 20
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 15
    },
    button: {
      textTransform: 'none',
      fontWeight: 600
    }
  })
)

export default function TransitionsModal (props: IProps) {
  const classes = useStyles()

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={props.open}>
          <Card className={classes.card}>
            <CardContent className={classes.content}>
              <Typography className={classes.title}>{props.title}</Typography>
              {props.children}
            </CardContent>
            <CardActions className={classes.buttons}>
              {props.cancel && <Button className={classes.button} onClick={props.handleClose} color='primary' size='small'>
                {props.cancelLabel || 'Cancel'}
              </Button>}
              <Button
                className={classes.button}
                onClick={props.handleSubmit}
                variant='contained'
                color='primary'
                size='small'
                disabled={props.disabled}
              >
                {props.submit || 'Done'}
              </Button>
            </CardActions>
          </Card>
        </Fade>
      </Modal>
    </div>
  )
}
