import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'

const TextInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 310,
      maxWidth: '92%',
      marginTop: 10
    }
  })
)(TextField)

export default TextInput
