import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const LargeButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      width: 'fit-content',
      paddingLeft: 0,
      fontSize: 14
    }
  })
)(Button)

export default LargeButton
