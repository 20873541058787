import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      width: "100%",
      height: "calc(100% - 136px)",
      background: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    image: {
      width: "100%",
      maxWidth: 472,
      height: "auto",
      objectFit: "cover",
      mixBlendMode: "multiply"
    },
    text: {
      fontSize: 24,
      fontWeight: "bold"
    }
  })
);

const Empty = () => {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <img className={classes.image} src="/empty_chat.png" alt="empty" />
      <Typography className={classes.text} variant="h2">
        It's time to send your first message!
      </Typography>
    </Box>
  );
};

export default Empty;
