import React, {useCallback, useContext, useState} from 'react'
import NewContact from '../../components/simpleComponents/NewContact'
import AddToGroup from '../../components/simpleComponents/Icon/AddToGroup'
import Modal from '../../components/simpleComponents/Modal/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import { setModal } from './redux/actions'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { CreateContactRequestType, SearchByKateResponseType } from '../../providers/APIClient/types'
import ContactsListView from './ContactsListView'
import KateNumberView from './KateNumberView'
import { parseKateNumber } from './parseKateNumber'
import theme from '../../theme'
import {APIClientContext} from '../../providers/APIClient'

const phones: {[key: number]: { name: string, label: string }} = {
  0: { name: 'work_phone', label: 'Work Phone' },
  1: { name: 'phone', label: 'Private Phone' },
  2: { name: 'cell_phone', label: 'Cell Phone' },
  3: { name: 'mobile_phone', label: 'Mobile Phone' },
  4: { name: 'fax', label: 'Fax' },
  5: { name: 'work_fax', label: 'Work Fax' }
}

const AddContact = () => {
  const APIClient = useContext(APIClientContext)
  const dispatch = useDispatch()
  const open = useSelector((state: RootState) => state.modal)

  const [inputKate, setInputKate] = useState(false)
  const [phoneItems, setPhoneItems] = useState<number[]>([0])
  const [selectedContact, setSelectedContact] = useState<number>(0)
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string | Error>('')
  const [watchKate, setWatchKate] = useState<string>('')

  const { register, handleSubmit, setValue, reset, errors } = useForm<CreateContactRequestType>()
  const [mutate] = useMutation(APIClient.createContact, {
    onSuccess: () => setSuccess(true),
    onError: (err) => setError((err as Error).message)
  })
  const onSubmit = async (data: CreateContactRequestType) => {
    Object.keys(data).forEach(key => {
      if (!data[key]) {
        delete data[key]
      }
    })
    await mutate(data)
  }

  const handleClose = useCallback(() => {
    reset()
    setWatchKate('')
    dispatch(setModal(false))
    setSuccess(false)
    setError('')
  }, [reset])

  const handleBack = useCallback(() => {
    setInputKate(false)
    setValue('kate_number', '')
  }, [])

  const handleSaveKate = useCallback(() => {
    setInputKate(false)
  }, [])

  const handleAddPhone = useCallback(() => {
    const toAdd = Object.keys(phones).find((key) => !phoneItems.includes(parseInt(key, 10)))
    if (toAdd) {
      setPhoneItems([...phoneItems, parseInt(toAdd, 10)])
    }
  }, [phoneItems])

  const handleDeletePhone = useCallback((id: number) => {
    const newArray = phoneItems.filter(item => item !== id)
    setPhoneItems(newArray)
  }, [phoneItems])

  const searchContact = useCallback(async (key: string, kateNumber: string) => {
    const matchingRe = /^\(\d{3}\) \d{3}-\d{4}$/
    if (kateNumber.match(matchingRe)) {
      return APIClient.getSearchByKateNumber(key, kateNumber)
    }
    return Promise.resolve(null)
  }, [])

  const { data: searchByKateResults } = useQuery(['searchByKate', parseKateNumber(watchKate)], searchContact)

  const searchAccounts = useCallback(async (key: string, data: SearchByKateResponseType | null | undefined, kateNumber: string) => {
    if (!data) return null
    return Promise.all(data.map(contact =>
      APIClient.getSearchAccounts(key, {
        first_name: contact.first_name,
        last_name: contact.last_name,
        kate_phone: kateNumber
      })))
  }, [])

  const {
    status: accountStatus,
    data: accountData
  } = useQuery(
      ['searchAccounts', searchByKateResults?.data, watchKate],
      searchAccounts,
      { enabled: !!(searchByKateResults && searchByKateResults.data) }
    )

  if (success) {
    return (
      <Modal
        handleSubmit={handleClose}
        open={open}
        title='New Contact'
      >
        <div>Contact created!</div>
      </Modal>
    )
  }

  if (error) {
    return (
      <Modal
        handleSubmit={handleClose}
        open={open}
        title='New Contact'
      >
        <div>Ups, something went wrong!</div>
        <div>{error}</div>
      </Modal>
    )
  }

  if (inputKate) {
    return (
      <Modal
        handleClose={handleBack}
        handleSubmit={handleSaveKate}
        open={open}
        title='New Contact'
        cancel
        cancelLabel='Back'
        disabled={accountStatus !== 'success' || !accountData?.[0].data?.[0]}
      >
        <NewContact>
          <NewContact.TextInput
            label='CommuniKate Number'
            style={{ marginBottom: 10 }}
            onChange={(e) => setWatchKate(e.target.value)}
          />
          <ContactsListView
            data={accountData || []}
            status={accountStatus}
            kateNumber={watchKate}
            onSelect={setSelectedContact}
            selected={selectedContact}
          />
        </NewContact>
      </Modal>
    )
  }

  return (
    <Modal
      handleClose={handleClose}
      handleSubmit={handleSubmit(onSubmit)}
      open={open}
      title='New Contact'
      cancel
      disabled={!accountData?.[selectedContact].data?.[0]?.account_id}
    >
      <NewContact>
        <KateNumberView
          data={accountData?.[selectedContact].data[0]}
          kateNumber={watchKate}
          handleClear={() => setWatchKate('')}
          handleSearchKate={() => setInputKate(true)}
        />
        <NewContact.TextInput
          label='Name'
          inputProps={{ name: 'first_name', readOnly: true }}
          inputRef={register}
          defaultValue={accountData?.[selectedContact].data?.[0]?.first_name}
        />
        <NewContact.TextInput
          label='Last Name'
          inputProps={{ name: 'last_name', readOnly: true }}
          inputRef={register}
          defaultValue={accountData?.[selectedContact].data?.[0]?.last_name}
        />
        <NewContact.TextInput
          label='Work Email'
          inputProps={{ name: 'work_email', readOnly: true }}
          inputRef={register}
          defaultValue={searchByKateResults?.data?.[0]?.emails?.[0]}
        />
          {phoneItems.map((item, index) =>
            <NewContact.Container key={index}>
              <NewContact.TextInput
                label={phones[item].label}
                inputProps={{ name: phones[item].name }}
                inputRef={register({
                  pattern: /[0-9]{10}/
                })}
                error={!!errors[phones[item].name]}
              />
              {index === 0
                ? <NewContact.AddButton onClick={handleAddPhone}/>
                : <NewContact.ClearButton color='secondary' onClick={() => handleDeletePhone(item)}/>
              }
            </NewContact.Container>
          )}
        <NewContact.LargeButton style={{ marginTop: 30 }} color='primary'>
          <AddToGroup fill={theme.palette.primary.main} style={{ marginRight: 5 }}/> Add to group
        </NewContact.LargeButton>
      </NewContact>
    </Modal>
  )
}

export default AddContact
