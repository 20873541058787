import React from 'react'
import { Box } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ListDivider from './Divider'
import JoinCallButton from './JoinCallButton'
import Caption from './Caption'
import Title from './Title'
import InfoContainer from './InfoContainer'
import CallTime from './CallTime'
import Channel from './Channel'
import Message from './Message'
import NewMessages from './NewMessages'
import AsideContainer from './AsideContainer'

interface IContactItem {
  children: React.ReactNode
  onClick? (props: any): void
  selected?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      background: theme.palette.background.default,
      height: 59,
      width: 356,
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette.background.paper
      }
    },
    selected: {
      background: theme.palette.background.paper,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      paddingLeft: 16
    }
  })
)

const ContactItem = (props: IContactItem) => {
  const classes = useStyles()
  const boxClass = props.selected ? `${classes.box} ${classes.selected}` : classes.box
  return (
    <Box className={boxClass} onClick={props.onClick}>
      {props.children}
    </Box>
  )
}

ContactItem.Divider = ListDivider
ContactItem.JoinCallButton = JoinCallButton
ContactItem.Caption = Caption
ContactItem.Title = Title
ContactItem.InfoContainer = InfoContainer
ContactItem.CallTime = CallTime
ContactItem.Channel = Channel
ContactItem.Message = Message
ContactItem.NewMessages = NewMessages
ContactItem.AsideContainer = AsideContainer

export default ContactItem
