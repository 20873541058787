import { createStyles, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const ListContainer = withStyles(() =>
  createStyles({
    root: {
      overflow: 'auto',
      height: 'calc(100% - 134px)',
      overflowX: 'hidden'
    }
  })
)(Box)

export default ListContainer
