import {
  ContactsResponseType,
  CreateContactRequestType,
  GroupsResponseType,
  LoginRequestType,
  LoginResponseType,
  MembersResponseType,
  MessageBody,
  SearchAccountsRequestType,
  SearchAccountsResponseType,
  SearchByKateResponseType
} from './types'
import { useMemo } from 'react'
import { AxiosInstance } from 'axios'

function useAPIClient (client: AxiosInstance) {
  return useMemo(() => {
    return {
      login (req: LoginRequestType) {
        return client.post<LoginResponseType>('/auth/login', req)
      },
      refreshToken (token: string | null) {
        return client.get<any>('/auth/refresh', {
          headers: {
            'Authorization': token
          }
        })
      },
      getTokboxToken (key: string, { channelId }: { channelId: string }) {
        return client.get(`/services/tokbox/access_token?channel_id=${channelId}`)
      },
      getUser () {
        return client.get('/me')
      },
      getGroups () {
        return client.get<GroupsResponseType>('/memberships')
      },
      getContacts () {
        return client.get<ContactsResponseType>('/contacts')
      },
      getGroupContacts (groupId: string) {
        return client.get<MembersResponseType>(`/groups/${groupId}/contacts`)
      },
      getChannel (key: string, { groupId, channelId }: { groupId: string, channelId: string }) {
        return client.get(`/groups/${groupId}/channel/${channelId}`)
      },
      createChannel ({ groupId, chatType }: { groupId: string, chatType: string }) {
        return client.post(`/groups/${groupId}/channel`, {
          'chat_type': chatType
        })
      },
      getPosts (key: string, channelId: string) {
        return client.get(`/channels/${channelId}/posts`)
      },
      sendMessage (messageBody: MessageBody) {
        const data = new FormData()
        data.append('json', JSON.stringify(messageBody))
        return client(`/posts`, {
          method: 'post',
          data,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      },
      getChannelInsights (key: string, channelId: string) {
        return client.get(`/channels/${channelId}/insights`)
      },
      getSearchAccounts (key: string, req: SearchAccountsRequestType) {
        return client.get<SearchAccountsResponseType>('/search/accounts', {
          params: req
        })
      },
      getSearchByKateNumber (key: string, kateNumber: string) {
        return client.get<SearchByKateResponseType>('/search/kate_phone', {
          params: {
            kate_number: kateNumber
          }
        })
      },
      createContact (req: CreateContactRequestType) {
        return client.post('/contacts', req)
      },
      twilioAccessToken () {
        return client.get('/services/twilio/access_token')
      }
    }
  }, [client])
}

export default useAPIClient
