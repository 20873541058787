import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

interface IProps {
  isSend?: boolean | undefined
  isError?: boolean
}

const Content = withStyles((theme: Theme) =>
  createStyles({
    root: (props: IProps) => ({
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      borderTopRightRadius: 20,
      padding: 15,
      fontSize: 14,
      lineHeight: '19px',
      maxWidth: 590,
      backgroundColor:
          props.isError
              ? theme.palette.error.main
              : props.isSend
                ? '#D9E9F7'
                : theme.palette.background.default,
      color:
          props.isError
              ? 'white'
              : theme.palette.text.primary
    })
  })
)(Typography)

export default Content
