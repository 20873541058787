import { useCallback, useContext } from 'react'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import { setAuth } from '../../redux/authentication/actions'
import { APIClientContext } from '../../providers/APIClient'

export default function useLogin () {
  const APIClient = useContext(APIClientContext)
  const dispatch = useDispatch()
  const [mutate] = useMutation(APIClient.login, {
    onSuccess: (data) => {
      localStorage.setItem('twilioToken', data.data.twilio_token)
      localStorage.setItem('xApiKey', data.data.x_api_key)
      dispatch(setAuth({ loggedIn: true }))
    },
    onError: (error) => {
      dispatch(setAuth({ loggedIn: false }))
      throw error
    }
  })

  const loginUser = useCallback(async (email: string, password: string) => {
    await mutate({
      email,
      password
    })
  }, [])

  return { loginUser }
}
