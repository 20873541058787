import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'

import useRoomState from '../../hooks/useRoomState/useRoomState'
import Fab from '@material-ui/core/Fab'
import Mic from '@material-ui/icons/Mic'
import MicOff from '@material-ui/icons/MicOff'
import Tooltip from '@material-ui/core/Tooltip'
import { Videocam, VideocamOff } from '@material-ui/icons'
import StopScreenShare from '@material-ui/icons/StopScreenShare'
import ScreenShare from '@material-ui/icons/ScreenShare'
import CallEnd from '@material-ui/icons/CallEnd'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
          bottom: `${theme.sidebarMobileHeight + 3}px`
        }
      },
      fab: {
        margin: theme.spacing(1),
        background: 'transparent',
        boxShadow: 'none'
      }
    })
)

interface IProps {
  roomState?: string
  toggleVideoSource: () => void
  videoSource: string
  screenSharingAvailable: boolean
}

export default function AdditionalVideoControls (props: IProps) {
  const classes = useStyles()
  const isReconnecting = props.roomState === 'Reconnecting'

  return (
    <div className={clsx(classes.container)}>
        <Tooltip
          title={props.videoSource === 'screen' ? 'Stop sharing' : 'Share screen'}
          placement='top'
          PopperProps={{ disablePortal: true }}
        >
          <Fab className={classes.fab} onClick={props.toggleVideoSource} disabled={isReconnecting}>
            {
                props.videoSource === 'camera'
                    ? <>
                        <ScreenShare />
                    </>
                    : <>
                        <StopScreenShare />
                    </>
            }
          </Fab>
        </Tooltip>
    </div>
  )
}
