import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Stream, Session } from '@opentok/client'
import Subscriber from './Subscriber'
import { styled } from '@material-ui/core/styles'

const Container = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
})

interface IProps {
  session: { [key: string]: any }
  streams: Stream[] | null
  setError: (error: Error) => void
}

const Subscribers = ({ session, setError, streams }: IProps) => {
  const callContainer = useRef(null)
  // const [screenSharingStreamId, setScreenSharingStreamId] = useState<string | null>(null)
  // const [bigStreamIds, setBigStreamIds] = useState<string[]>([])
  const [streamSrc, setStreamSrc] = useState<any>(null)
  const [bigStreamId, setBigStreamId] = useState<string | null>(null)

  const checkIfStillSharing = () => {
    // console.log('bigStreamId', bigStreamId)
    // console.log('streams', streams)
    if (!streams?.find(stream => stream.streamId === bigStreamId)) {
      setBigStreamId(null)
    }
  }

  const getSubscriberStyles = (streamId: string, index: number, streamVideoType: string) => {
    // console.log('getSubscriberStyles...')
    const bigStream = streamVideoType === 'screen'
    const streamNumber = index + 1

    if (bigStream) {
      return {
        position: 'absolute',
        flex: 1,
        width: '100%',
        height: '100%'
      }
    }

    if (!streams || streams?.length <= 1) {
      return {
        position: 'relative',
        flex: 1,
        width: '100%',
        height: '100%'
      }
    }

    if (bigStreamId && bigStreamId !== streamId) {
      return {
        width: 120,
        height: 120,
        position: 'absolute',
        right: 0,
        zIndex: 3,
        top: 120 * (index + 1)
      }
    }

    if (streams.length <= 2) {
      return {
        position: 'relative',
        width: '50%',
        height: '100%'
      }
    }

    if (streams.length > 2 && streamNumber <= 4) {
      return {
        position: 'relative',
        width: '50%',
        height: '50%'
      }
    }

    if (streams.length > 4 && streamNumber > 4) {
      return {
        zIndex: 3,
        width: 120,
        height: 120,
        position: 'absolute',
        right: 0,
        top: 120 * (streamNumber - 4)
      }
    }

    return {
      zIndex: 3,
      width: 120,
      height: 120,
      position: 'absolute',
      right: 0,
      top: 120 * (index + 1)
    }
  }

  return (
      <Container
          ref={callContainer}
          id='layoutContainer'
          className='layout-container'
      >
        {/*<div*/}
        {/*    style={{*/}
        {/*      position: 'absolute',*/}
        {/*      width: '300px',*/}
        {/*      height: '300px',*/}
        {/*      top: 0,*/}
        {/*      left: 0,*/}
        {/*      zIndex: 9999*/}
        {/*    }}*/}
        {/*>*/}
        {/*  <video id='captureStream' width='100%' height='100%' />*/}
        {/*</div>*/}
          {streams?.map((stream: Stream, index: number) => {
              // console.log('stream', stream)
            return (
                <Subscriber
                  key={stream.streamId}
                  stream={stream}
                  session={session}
                  checkIfStillSharing={checkIfStillSharing}
                  setBigStreamId={setBigStreamId}
                  setStreamSrc={setStreamSrc}
                  setError={setError}
                  style={{
                    cursor: 'pointer',
                    ...getSubscriberStyles(stream.streamId, index, stream.videoType)
                  }}
                />
            )
          })}
      </Container >
  )
}

export default Subscribers
