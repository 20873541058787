import React, { FC } from 'react'
import ChatChannelPageView from './ChatChannelPageView'
import { useHistory } from 'react-router-dom'

const ChatChannelPage: FC = () => {
  const history = useHistory()
  const listType = history.location.hash.substr(1)
  const search = history.location.search.substr(1)
  return (
    <ChatChannelPageView listType={search || listType} />
  )
}

export default ChatChannelPage
