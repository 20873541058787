import React from 'react'
import { IconButton } from '@material-ui/core'

interface IProps {
  fill: string
  style?: any
  onClick? (props: any): void
}

const AddToGroup = (props: IProps) => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' style={props.style}>
      <path
        fill={props.fill}
        d='M12.3 0.800003C11.0244 0.800003 10.8 1.7 10.8 1.7C10.3542 1.7 9.9979 1.86692 9.7207 2.14532C10.0279 2.80652 10.2 3.5684 10.2 4.4C10.2 4.8176 10.1172 5.2227 10.0242 5.5461C10.1484 5.8395 10.2094 6.19224 10.1578 6.59844C10.0954 7.08744 9.94778 7.46448 9.75938 7.76328C9.87398 8.09388 10.0844 8.43258 10.2938 8.70078C11.2382 7.89198 12.462 7.4 13.8 7.4C14.1786 7.4 14.5481 7.44045 14.9051 7.51485C14.9105 7.48665 14.9197 7.45633 14.9227 7.42813C15.1351 7.41193 15.4688 7.21707 15.5672 6.44727C15.62 6.03447 15.4108 5.80211 15.2836 5.72891C15.2836 5.72891 15.6 5.0624 15.6 4.4C15.6 2.4326 14.3928 0.800003 12.3 0.800003ZM5.7 0.801175C4.4244 0.801175 4.2 1.70117 4.2 1.70117C2.9718 1.70117 2.4 2.94438 2.4 4.40118C2.4 5.12778 2.71641 5.72891 2.71641 5.72891C2.58921 5.80211 2.38001 6.03387 2.43281 6.44727C2.53121 7.21707 2.86494 7.4131 3.07734 7.4293C3.15834 8.1475 3.93 9.0662 4.2 9.2V10.4C3.6 12.2 0 11 0 15.2H8.96953C8.60773 14.4758 8.4 13.6628 8.4 12.8C8.4 12.3554 8.46032 11.9255 8.56172 11.5121C7.93772 11.2673 7.4004 11.0012 7.2 10.4V9.2C7.47 9.0662 8.24166 8.14693 8.32266 7.42813C8.53506 7.41193 8.86879 7.21707 8.96719 6.44727C9.01999 6.03447 8.81079 5.80211 8.68359 5.72891C8.68359 5.72891 9 5.0624 9 4.4C9 2.4326 7.7928 0.800575 5.7 0.801175ZM13.8 8.6C11.4804 8.6 9.6 10.4804 9.6 12.8C9.6 15.1196 11.4804 17 13.8 17C16.1196 17 18 15.1196 18 12.8C18 10.4804 16.1196 8.6 13.8 8.6ZM13.8 10.4C14.1312 10.4 14.4 10.6682 14.4 11V12.2H15.6C15.9312 12.2 16.2 12.4682 16.2 12.8C16.2 13.1318 15.9312 13.4 15.6 13.4H14.4V14.6C14.4 14.9318 14.1312 15.2 13.8 15.2C13.4688 15.2 13.2 14.9318 13.2 14.6V13.4H12C11.6688 13.4 11.4 13.1318 11.4 12.8C11.4 12.4682 11.6688 12.2 12 12.2H13.2V11C13.2 10.6682 13.4688 10.4 13.8 10.4Z'
      />
    </svg>
  )
}

export default AddToGroup
