import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ChatChannelPage from './pages/ChatChannelPage'
import VonageApp from './Vonage/VonageApp'
import { TwilioChatProvider } from './providers/TwilioChat'

const App = () => {
  return (
      <TwilioChatProvider>
          <Router>
            <Switch>
              <Route exact path='/'>
                <ChatChannelPage />
              </Route>
              <Route path='/chat/:roomId'>
                <ChatChannelPage />
              </Route>
              <Route path='/video/:channelId'>
                <VonageApp />
              </Route>
            </Switch>
          </Router>
      </TwilioChatProvider>
  )
}

export default App
