import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const ResultsHeader = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
      fontSize: 12,
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
      margin: '15px 0 5px 20px'
    }
  })
)(Typography)

export default ResultsHeader
