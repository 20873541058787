import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const Caption = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.secondary.light,
      fontSize: 12,
      overflow: 'hidden',
      height: 15,
      maxWidth: 200,
      marginTop: 5,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  })
)(Typography)

export default Caption
