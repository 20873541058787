import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const ActionsContainer = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
)(Box)

export default ActionsContainer
