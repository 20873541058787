import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const Subtitle = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      fontSize: 12,
      lineHeight: '16px'
    }
  })
)(Typography)

export default Subtitle
