import React, { FC, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
// @ts-ignore
import { OTSubscriber, createSession } from 'opentok-react'
import Publisher from './Publisher'
import { API_KEY } from './config'
import { useHistory, useParams } from 'react-router-dom'
import { styled } from '@material-ui/core/styles'
import useHeight from '../hooks/useHeight/useHeight'
import WaitingRoom from '../containers/WaitingRoom'
import { useQuery } from 'react-query'
import { APIClientContext } from '../providers/APIClient'
import Webcam from 'react-webcam'
import { Stream } from '@opentok/client'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/reducers'
import useUser from '../hooks/useUser/useUser'
import VideoCallFooter from '../components/VideoCallFooter'
import ScreenSharingOverlay from '../components/ScreenSharingOverlay'
import Subscribers from './Subscribers'

const Container = styled('div')({
  display: 'flex'
})

enum EnumVideoSource {
  camera = 'camera',
  screen = 'screen'
}

const VonageApp: FC = () => {
  const APIClient = useContext(APIClientContext)
  const history = useHistory()
  const [error, setError] = useState<Error | null>(null)
  const [connection, setConnection] = useState<string>('Connecting')
  const [audio, setAudio] = useState<boolean>(true)
  const [video, setVideo] = useState<boolean>(true)
  const [videoSource, setVideoSource] = useState<string>('camera')
  const [joined, setJoined] = useState<boolean>(false)
  const [streams, setStreams] = useState<Stream[] | null>(null)
  const [streamsLength, setStreamsLength] = useState<number>(0)

  useUser()
  const user = useSelector((state: RootState) => state.user)

  const { channelId } = useParams()
  const { data: tokenRes, status: tokenStatus } = useQuery(
    ['tokboxToken', { channelId: channelId || '' }],
    APIClient.getTokboxToken,
    {
      enabled: !!channelId,
      refetchOnWindowFocus: false
    }
  )

  const sessionEventHandlers = {
    sessionConnected: () => setConnection('Connected'),
    sessionDisconnected: () => setConnection('Disconnected'),
    sessionReconnected: () => setConnection('Reconnected'),
    sessionReconnecting: () => setConnection('Reconnecting')
  }

  const publisherEventHandlers = {
    accessDenied: () => {
      console.log('User denied access to media source')
      if (videoSource === 'screen') setVideoSource('camera')
    },
    streamCreated: (element: any) => {
      console.log('Publisher stream created', element)
    },
    streamDestroyed: ({ reason }: {reason: string}) => {
      console.log(`Publisher stream destroyed because: ${reason}`)
    }
  }

  const onSessionError = (error: Error) => setError(error)
  const onPublishError = (error: Error) => setError(error)
  const onPublish = () => {

    console.log('Publish Success')
  }
  const toggleVideoSource = () => setVideoSource(videoSource === 'camera' ? 'screen' : 'camera')
  const toggleVideo = () => setVideo(!video)
  const toggleAudio = () => setAudio(!audio)
  const quitCall = () => history.replace(`/chat/${channelId}`)

  const onStreamsUpdated = (streams: Stream[]) => {
    setStreams([...streams])
    setStreamsLength(streams.length)
  }

  useEffect(() => {
    console.log('streams updated', streams)
  }, [streams])

  const sessionHelper = useMemo(() => {
    if (tokenRes?.data.session_id && tokenRes?.data.access_token) {
      return createSession({
        apiKey: API_KEY,
        sessionId: tokenRes.data.session_id,
        token: tokenRes.data.access_token,
        onStreamsUpdated,
        onError: onSessionError,
        onConnect: sessionEventHandlers.sessionConnected
      })
    }
    return null
  }, [tokenRes, channelId, window.location])

  const height = useHeight()

  if (!joined || !sessionHelper) {
    return (
        <WaitingRoom
            streams={streams}
            onJoin={() => setJoined(true)}
            video={video}
            toggleVideo={toggleVideo}
            audio={audio}
            toggleAudio={toggleAudio}
        >
          {video
              ? <Webcam width='100%' />
              : <div style={{ width: '100%', background: 'rgba(0,0,0,0.1)', height: '400px' }}/>
          }
        </WaitingRoom>
    )
  }

  return (
    <Container
        style={{ height, background: '#222' }}
    >
      <Subscribers
          session={sessionHelper.session}
          streams={streams}
          setError={setError}
      />
      {videoSource === 'screen' &&
        <ScreenSharingOverlay onStopSharingClick={toggleVideoSource} />
      }
      <Publisher
        session={sessionHelper.session}
        className='streamSharing'
        videoSource={videoSource}
        properties={{
          publishVideo: video,
          publishAudio: audio,
          name: `${user.first_name || ''} ${user.last_name || ''}`
        }}
        onPublish={onPublish}
        onError={onPublishError}
        eventHandlers={publisherEventHandlers}
      />
      <VideoCallFooter
          roomState={connection}
          error={error}
          toggleVideo={toggleVideo}
          toggleAudio={toggleAudio}
          toggleVideoSource={toggleVideoSource}
          videoEnabled={video}
          audioEnabled={audio}
          screenSharingAvailable={true}
          videoSource={videoSource}
          quitCall={quitCall}
      />
    </Container>
  )
}

export default VonageApp
